import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    .container-columns-table {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        margin: 16px 0 32px;
        
        > div {
            flex: 1;
            flex-basis: calc(25% - 10px);
        }
    }

    .button-actions {
        display: flex;
        justify-content: flex-end;
        
        button + button {
            margin-left: 16px;
        }
    }
`;