import { useCallback, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { shade, lighten } from 'polished';
import Modal from '../Modal';
import {
    parseISO,
    formatDistance
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { 
    Container,
    NotChangelogs,
} from './style';
export const Changelog = ({ logs, loading = false }) => {
    
    const [openModal, setOpenModal] = useState(false);
    const [logSelected, setLogSelected] = useState(null);
    const { colors } = useContext(ThemeContext);

    const handleFormatDate = useCallback((date) => {
        const parsedDate = parseISO(date);
        const distanceDate = formatDistance(parsedDate, new Date(), {
            addSuffix: true,
            locale: ptBR,
        });
        return distanceDate;
    }, []);

    const handleSelectLog = useCallback((log) => {
        setLogSelected(log);
        setOpenModal(true);
    }, []);

    return (
        <Container>
            <Modal
                showModal={openModal}
                setShowModal={setOpenModal}
                title={logSelected && logSelected.title}
            >
                {logSelected && (
                    <ReactMarkdown>{logSelected.content}</ReactMarkdown>
                )}
            </Modal>
            <ul>
                {(loading && !logs.length) && Array.from({ length: 3 }).map(() => (
                    <SkeletonTheme
                        color={shade(0.2, colors.superimposedBackground)} 
                        highlightColor={lighten(0.05, colors.superimposedBackground)}
                    >
                        <li>
                            <div className="date">
                                <span><Skeleton height={12} width={100}/></span>
                            </div>
                            <div className="content">
                                <header>
                                    <Skeleton height={24} width={500}/>
                                    <Skeleton height={31} width={59}/>
                                </header>
                                <div className="body-content">
                                    <Skeleton height={60}/>
                                    <div style={{ marginTop: 8}}>
                                        <Skeleton height={16} width={74}/>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </SkeletonTheme>
                ))}
                {logs.length ? logs.map(log => (
                    <li>
                        <div className="date">
                            <span>{handleFormatDate(log.published_at)}</span>
                        </div>
                        <div className="content">
                            <header>
                                <strong>{log.title}</strong>
                                <span className="badge-new">Novo</span>
                            </header>
                            <div className="body-content">
                                <ReactMarkdown>{`${log.content.slice(0, 300)}...`}</ReactMarkdown>
                                <button onClick={() => handleSelectLog(log)}>Saber mais</button>
                            </div>
                        </div>
                    </li>
                )) : (
                    <NotChangelogs >
                        <small>Nenhuma atualização disponível.</small>
                    </NotChangelogs>
                )}
            </ul>
        </Container>
    );
}