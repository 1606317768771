import { useCallback, useState } from 'react';
import { UseToast } from '../../../hooks/ToastContext';
import DropDown from '../../../components/Dropdown';
import Button from '../../../components/Button';
import { Container } from './style';
import usePersistState from '../../../utils/usePersistState';

const optionsColumn = [
    {label: '#Área m²', value: 'privateArea', abbr: 'Área m2'},
    {label: '#Área m² R$', value: 'm2Real', abbr: 'm2 R$'},
    {label: '#Á vista', value: 'indexedQuantity', abbr: 'Á vista'},
    {label: '#BLOCO', value: 'qd', abbr: 'BL'},
    {label: '#ENTRADA %', value: 'inputValue', abbr: 'Entrada %'},
    {label: '#ENTRADA R$', value: 'inputValueReal', abbr: 'Entrada R$'},
    {label: '#FINANCIAMENTO', value: 'amount', abbr: 'F'},
    {label: '#ID', value: 'id', abbr: 'id'},
    {label: '#QUADRA', value: 'qd', abbr: 'QD'},
    {label: '#LOTE', value: 'lt', abbr: 'LT'},
    {label: '#STATUS', value: 'commercialStock', abbr: 'Status'},
    {label: '#TIPO', value: 'propertyType', abbr: 'Tipo'},
    {label: '#NULO', value: null, abbr: 'null'},
];

const optionDefault = [
    {label: '#QUADRA', value: 'qd', abbr: 'qd'},
    {label: '#LOTE', value: 'lt', abbr: 'lt'},
    {label: '#Área m²', value: 'privateArea', abbr: 'Área m2'},
    {label: '#Á vista', value: 'indexedQuantity', abbr: 'Á vista'},
    {label: '#ENTRADA %', value: 'inputValue', abbr: 'Entrada %'},
    {label: '#FINANCIAMENTO', value: 'amount', abbr: 'F'},
];

const numberOfInstallments = [
    {value: null, label: '#NULO'},
    {value: 12, label: '12x'},
    {value: 24, label: '24x'},
    {value: 36, label: '36x'},
    {value: 48, label: '48x'},
    {value: 60, label: '60x'},
    {value: 72, label: '72x'},
    {value: 84, label: '84x'},
    {value: 96, label: '96x'},
    {value: 108, label: '108x'},
    {value: 108, label: '108x'},
    {value: 120, label: '120x'},
    {value: 132, label: '132x'},
    {value: 144, label: '144x'},
    {value: 156, label: '156x'},
    {value: 168, label: '168x'},
    {value: 180, label: '180x'},
];

export const SettingsExportDocument = ({showModal = false, setShowModal}) => {
    
    const [ headerExportDocument, setHeaderExportDocument ] = usePersistState('@amoraid:configDocumentExportHeaderInstallments', optionDefault);
    const [numberInstallmentsDocument, setNumberInstallmentDocument ] = usePersistState('@amoraid:configDocumentExportNumberInstallments', numberOfInstallments[numberOfInstallments.length]);

    const [columnsTable, setColumnsTable] = useState(() => {
        if(!headerExportDocument) return optionDefault;
        return headerExportDocument;
    });

    const [ numberInstallments, setNumberInstallments ] = useState(() => {
        if(!numberInstallmentsDocument) return numberOfInstallments[numberOfInstallments.length];
        return numberInstallmentsDocument;
    })

    const { addToast } = UseToast();
    
    const handleOnchangeSelectHeaderTable = useCallback((position, item) => {
        let newData = columnsTable.map((column, index) => {
            if(index === position) return item;
            return column;
        });
        setColumnsTable(newData);
    }, [columnsTable]);

    const handleSubmit = useCallback(() => {
        setHeaderExportDocument(columnsTable);
        setNumberInstallmentDocument(numberInstallments);
        addToast({
            type: 'success',
            title: 'Configurações aplicadas com sucesso',
        });
    }, [columnsTable, setHeaderExportDocument, addToast, numberInstallments, setNumberInstallmentDocument]);

    const handleSetNumberInstallments = useCallback((value) => {
        setNumberInstallments(value);
    }, []);

    return (
        <Container>
            <strong>Cabeçalho da tabela</strong>
            <div className="container-columns-table">
                {columnsTable.map((header, index) => (
                    <DropDown 
                        key={index}
                        id={`row${index}`}
                        prompt={`${index + 1} coluna`}
                        label="label"
                        value={header}
                        options={optionsColumn}
                        onChange={(value) => handleOnchangeSelectHeaderTable(index, value)}
                        overlay
                    />
                ))}
            </div>
            <strong>Opções de financiamento</strong>
            <div className="container-columns-table">
                <DropDown 
                    id="installments"
                    prompt="Quantidade de parcelas"
                    label="label"
                    value={numberInstallmentsDocument || numberOfInstallments[15]}
                    options={numberOfInstallments}
                    onChange={(value) => handleSetNumberInstallments(value)}
                    overlay
                />
            </div>            
            <div className="button-actions">
                <Button onClick={handleSubmit} colorStyle="success">Aplicar</Button>
            </div>
        </Container>
    );
}
