import { useCallback, useState, useRef, useEffect, useContext } from 'react';
import { useField } from '@unform/core';
import { ThemeContext } from 'styled-components';
import ToolTip from '../../components/Tooltips';
import { Container, InputContainer } from './style';

function Input({label, name, id, onChange = null, overlay, icon: Icon, ...rest})
{
    const { colors } = useContext(ThemeContext);

    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [filename, setFilename] = useState(null);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const  handleInputBlur = useCallback(() =>{
        setIsFocused(false);

        // transformando em uma condição super curta e transformando o resultado em boolean
        setIsFilled(!!inputRef.current?.value);
    }, []);

    const handleInputOnChange = useCallback((e) => {
        let file = e.target.files[0];
        if(e.target.files[0]) setFilename(file.name);
        // console.log(e.target.files[0]);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'files[0]',
            clearValue(ref){
                ref.value = "";
            },
        });
    }, [fieldName, registerField]);
    
    return (
        <>
        <Container isFocused={isFocused} isFilled={isFilled} overlay={overlay} isErrored={!!error}>
            <span>{label}</span>
            <InputContainer>
                <label for={id}>
                    {filename ? filename : "Selecione o arquivo"}
                </label>
                <input 
                    ref={inputRef}
                    defaultValue={defaultValue}
                    id={id} 
                    name={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    type="file"
                    {...rest}
                    onChange={(e) => handleInputOnChange(e)}
                />
                {Icon && (
                    !!error ? (
                        <ToolTip title={error}>
                            <Icon size={17} color={colors.danger} />
                        </ToolTip>
                    )
                    : <Icon size={17} color={colors.label} />
                )}
            </InputContainer>
        </Container>
        </>
    );
}

export default Input;