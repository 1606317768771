import React, { useEffect, useState } from 'react';
import { api } from '../../services/api-cms';
import { useAuth } from '../../hooks/Auth';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import Card from '../../components/CardContainer';
import { Changelog } from '../../components/Changelog';
import {  Container } from './style';
import { 
    Flex,
    Heading,
    Spacer,
    Text,
 } from '@chakra-ui/react';

const Dashboard = () => {
    const { user } = useAuth();
    const [changelogs, setChangelogs] = useState([]);
    const [loadingLogs, setLoadingLogs] = useState(false);

    const currentDate = format(new Date(), 'EEEE, d MMMM', {
        locale: ptBR,
    });

    useEffect(() => {
        async function getChangelogs() {
            try {
                setLoadingLogs(true);
                const { data } = await api.get('/changelogs');
                setChangelogs(data);
                setLoadingLogs(false);
            } catch {
              setLoadingLogs(false);
            }
        }
        getChangelogs();
    },[]);

    return (
        <>
            <Flex marginBottom={4}>
                <Heading as="h1" size="lg">
                    Seja bem vindo, <Text size="lg" color="blue.100">{user.first_name} {user.last_name}.</Text>
                </Heading>
                <Spacer />
                <Text color="gray.600">{currentDate}</Text>
            </Flex>
            <Container style={{paddingTop: 16}}>
                <Card>
                    <div className="header">
                        <Heading as="h2" size="md">Atualizações</Heading>
                        <p>Conheça novas funcionalidades que estamos produzindo.</p>
                    </div>  
                    <Changelog logs={changelogs} loading={loadingLogs}/>
                </Card>
            </Container>
        </>
    );
}

export default Dashboard;