import React, { useCallback, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { UseToast } from '../../hooks/ToastContext';

import imageBanner from '../../assets/pixeltrue-success.svg';
import logotipo from '../../assets/amora-dark-old.png';

import Input from '../../components/Input';
import { Form } from '@unform/web';
import Button from '../../components/Button';
import { 
    FiMail, 
    FiUser, 
    FiLock, 
} from 'react-icons/fi';
import * as Yup from 'yup';
import api from '../../utils/api';

import { 
    Wrapper, 
    Background, 
    Content, 
    AnimationContainer,
} from './style';
import { Heading } from '@chakra-ui/layout';

function SignUp(){
    const formRef = useRef(null);
    const history = useHistory();
    const { addToast } = UseToast();

    const handleSignUp = useCallback(async (data) => {
        
        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                first_name: Yup.string().required("Por favor, informe seu nome"),
                last_name: Yup.string().required("Por favor, informe seu sobrenome"),
                email: Yup.string().email('Email informado não é válido').required("E-mail é obrigatório"),
                password: Yup.string().min(6, "Senha deve ter +6 caracteres"),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            await api.post('/users', data);

            addToast({
                type: 'success',
                title: 'Cadastro realizado com sucesso.'
            });

            history.push('/');
        } catch (err) {
            const validationErrors = {};

            if(err instanceof Yup.ValidationError){
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });

                formRef.current.setErrors(validationErrors);
                return;
            }

            if(err.response.status === 400) {
                addToast({
                    type: 'error',
                    title: err.response.data.message,
                });
                return;
            }

            addToast({
                type: 'error',
                title: 'Ops, Houve um erro',
                description: 'Erro inesperado, tenta mais tarde.',
            });

        }
      
    }, [history, addToast]);
    
    return (
        <Wrapper>
            <Content>
                <AnimationContainer>
                    <Form ref={formRef} onSubmit={handleSignUp}>
                        <Heading as="h1" size="lg">Faça seu cadastro</Heading>
                        <p>Já é membro? <Link to="/">faça seu login.</Link></p>
                        <Input id="first_name" label="Nome" type="text" name="first_name" onChange={() => {}} Icon={FiUser} placeholder="Elon"/>
                        <Input id="last_name" label="Sobrenome" type="text" name="last_name" onChange={() => {}} Icon={FiUser} placeholder="Musk"/>
                        <Input id="email" label="E-mail" type="email" name="email" onChange={() => {}} Icon={FiMail} placeholder="elonmusk@spacex.com"/>
                        <Input id="password" label="Senha" type="password" name="password"onChange={() => {}} Icon={FiLock} placeholder="+6 caracteres"/>
                
                        <Button type="submit" colorStyle="primary">Cadastrar</Button>
                    </Form>
                    
                </AnimationContainer>
            </Content>
            <Background>
                <div className="brand">
                    <img src={logotipo} alt="amora.id"/>
                    <p>Cadastre-se agora mesmo e conheça a ferramenta que vai facilitar toda a reunião das principias informações do seu negócio, aumentando sua eficiência e produtividade.</p>
                </div>
                <img src={imageBanner} className="image-highlights" alt="Pixeltrue by Pixeltrue"/>
            </Background>
        </Wrapper>
    )
};

export default SignUp;