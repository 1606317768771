import styled, { css } from 'styled-components';
import { shade, darken } from 'polished';

const colors = {
    primary: '#3B86FF',
    danger: '#d5211d',
    success: '#5ee2a0',
}

export const Container = styled.button`
    height: 56px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 8px 16px;
    background: ${props => props.theme.colors.primary};
    transition: 0.2s ease;
    
    ${props => props.blockspace && css`
        width: 100%;
    `}

    ${props => props.colorStyle && css`
        background: ${props.colorStyle === 'secondary' 
        ? darken(0.02, props.theme.colors.superimposedBackground) 
        : colors[props.colorStyle]};
    `}

    color: #fff;
    font-size: 17px;
    font-weight: bold;
    border: 0;

    &:hover{
        background: ${props => shade(0.2, props.theme.colors.primary)};
        
        ${props => props.colorStyle && css`
            background: ${props.colorStyle === 'secondary' 
        ? shade(0.2, darken(0.02, props.theme.colors.superimposedBackground))
        : shade(0.2, colors[props.colorStyle])};
        `}
    }
`;