import React, { createContext, useCallback, useContext, useState } from 'react';
import ToastContainer from '../components/ToastContainer';
import { v4 as uuid } from 'uuid';

const ToastContext = createContext();

const ToastProvider = ({children}) => {

    const [messages, setMessages] = useState([]);

    const addToast = useCallback(({type, title, description}) => {
        const id = uuid();
        const toast = {
            id,
            type,
            title,
            description,
        }

        setMessages(prev => [...prev, toast]);
    },[]);
    const removeToast = useCallback((id) => {
        setMessages(prev => prev.filter(message => message.id !== id));
    },[]);

    return (
        <ToastContext.Provider value={{ addToast, removeToast}}>
            {children}
            <ToastContainer messages={messages}/>
        </ToastContext.Provider>
    );
}

const UseToast = () => {
    const context = useContext(ToastContext);

    if(!context) throw new Error('useToast must be used within a ToastProvider');

    return context;
}

export { ToastProvider, UseToast };