import calculateFinancing from './calculateFinancing';

const sortNumber = (a, b) => {
    if(a.id < b.id) return -1;
    if(a.id > b.id) return 1;
    return 0;
}
const sortByBlock = (a, b) => {
    var a_qd_number =  Number(a.qd.replace(/\D/g, ''));
    var b_qd_number = Number(b.qd.replace(/\D/g, ''));

    var a_lt_number =  Number(a.lt.replace(/\D/g, ''));
    var b_lt_number = Number(b.lt.replace(/\D/g, ''));
        
    if(a_qd_number < b_qd_number) return -1;
    if(a_qd_number > b_qd_number) return 1;
    if(a_lt_number < b_lt_number) return -1;
    if(a_lt_number > b_lt_number) return 1;
    return 0;
};
/**
 * Verifica as condições da configuração da table price
 */
function verifyApplyFees(fees, tablePrice, configSelectFees, numberInstallment) {
    if(fees) {
        return tablePrice;
    }
    if((!fees && configSelectFees) && (numberInstallment >= configSelectFees.value)) return tablePrice;
    return 0; 
}

export default function calculateInstallments(data = [], inputValue, tablePrice = 0, fees = true, configSelectFees = null) {
    return new Promise((resolve, reject) => {
        if(data.length < 1) reject("Este empreedimento não possui nenhuma unidade.");
       
        setTimeout(() => {
            const format = data.map(item => {
                let indexedQuantity = Number(item.indexedQuantity);
                var qd, lt;

                if(item.name.includes('_')) {
                    let nameFormat = item.name.split('_');
                    qd = nameFormat[0];
                    lt = nameFormat[1];
                }else{
                    qd = item.name;
                    lt = item.name;
                }
                
                let subtractedValue = (indexedQuantity - ((inputValue / 100) * indexedQuantity));
                let currentItem = {
                    id: item.id,
                    qd,
                    lt,
                    commercialStock: item.commercialStock,
                    propertyType: item.propertyType,
                    name: item.name,
                    inputValue,
                    floor: item.floor,
                    inputValueReal: (indexedQuantity - subtractedValue).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    privateArea: item.privateArea.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m2Real: (subtractedValue / item.privateArea).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    indexedQuantity: indexedQuantity.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    amount: subtractedValue.toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}), 
                    m12:  calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 12), 12).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m24:  calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 24), 24).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m36:  calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 36), 36).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m48:  calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 48), 48).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m60:  calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 60), 60).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m72:  calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 72), 72).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m84:  calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 84), 84).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m96:  calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 96), 96).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m108: calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 108), 108).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m120: calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 120), 120).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m132: calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 132), 132).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m144: calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 144), 144).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m156: calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 156), 156).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m168: calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 168), 168).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),
                    m180: calculateFinancing(subtractedValue, verifyApplyFees(fees, tablePrice, configSelectFees, 180), 180).toLocaleString('pt-br', { maximumFractionDigits: 2, minimumFractionDigits: 2}),  
                }
    
                return currentItem;
            });
            /** order by id */
            var formatOrder;
            formatOrder = format.sort(sortNumber);
            /** order by bl | qd | lt */
            formatOrder = formatOrder.sort(sortByBlock);
            resolve(formatOrder);
        });
    });
}
