import React, { createContext, useCallback, useContext } from 'react';
import { ThemeProvider } from 'styled-components';

import usePersistState from '../utils/usePersistState';
import light from '../styles/theme/light';
import dark from '../styles/theme/dark'; 

import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '../styles/theme';

const ThemeContext = createContext();

const MyThemeProvider = ({ children }) => {
    
    const [themeStyled, setTheme] = usePersistState('@amoraid:theme', dark);
    
    const toggleTheme = useCallback(() => {
        setTheme(themeStyled.title === 'light' ? dark : light);
    }, [themeStyled, setTheme]);

    return (
        <ThemeProvider theme={themeStyled}>
            <ThemeContext.Provider value={{ toggleTheme }}>
                    <ChakraProvider theme={theme}>
                        {children}
                    </ChakraProvider>
            </ThemeContext.Provider>
        </ThemeProvider>
    )
};

export function useTheme(){
    const context = useContext(ThemeContext);
    return context;
}

export default MyThemeProvider;