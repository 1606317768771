import React, { 
    useState, 
    useCallback, 
    useRef, 
    useEffect 
} from 'react';
import { Container, Input } from './style';

function DropDown({
    prompt, 
    id, 
    label, 
    options, 
    onChange,
    value = null, 
    loading = false,
    overlay = false
}){
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [query, setQuery] = useState(null);
   
    const handleCloseDropDown = useCallback((e) => {
        setOpenDropdown(e && e.target === inputRef.current);
    }, [inputRef]);

    useEffect(() => {
        document.addEventListener('click', handleCloseDropDown);

        return () => document.removeEventListener('click', handleCloseDropDown);

    }, [handleCloseDropDown]);


    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
        // transformando em uma condição super curta e transformando o resultado em boolean
        setIsFilled(!!inputRef.current?.value);
    }, []);

    const handleClickOption = useCallback((option) => {
        setOpenDropdown(false);
        onChange(option);
        inputRef.current.value = Array.isArray(label) ? `${option[label[0]]} - ${option[label[1]]}` : option[label];
    }, [onChange, inputRef, label]);

    const filter = useCallback((data) => {
        if(query){
            const dataFilter = data.filter(item => {
                const itemLowerCase = item.name.toLowerCase();
                // console.log(item, itemLowerCase);
                const valueLowerCase = query.toLowerCase();

                if(itemLowerCase.includes(valueLowerCase)) return item;

                return null;
            });

            if(dataFilter) return dataFilter;
            return;
        }

        return data;
    
    }, [query]);
    
    const handleChangeInput = useCallback((e) => {
        setQuery(e.target.value)
        if(e.target.value) setOpenDropdown(true);
    }, []);

    useEffect(() => {
        if(value) inputRef.current.value = value[label];
    }, [value, label]);

    return (
        <Container openDropdown={openDropdown} loading={loading}> 
            <Input isFocused={isFocused} isFilled={isFilled} overlay={overlay}>
                <label htmlFor={id}>{prompt}</label>
                <div className="selected-value">
                    <input 
                        ref={inputRef}
                        type="text"
                        id={id}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        onClick={() => setOpenDropdown(prev => !prev)}
                        onChange={e => handleChangeInput(e)}
                        disabled={loading}
                        autoComplete="off"
                     />
                </div>
            </Input>
            <ul>
                {filter(options).map((option, index) => (
                    <li 
                    key={option.id || index}
                    onClick={() => handleClickOption(option)}
                    >{Array.isArray(label) ? `${option[label[0]]} - ${option[label[1]]}` : option[label]}</li>
                ))}
            </ul>
        </Container>
    );
}

export default DropDown;