import styled from 'styled-components';

export const Container = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    
    form{
        display: flex;

        div + div {
            margin-left: 16px;
        }

        div + button {
            margin-left: 16px;
        }
    }
`;