import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.table`

    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    position: relative;

    
    th,
    td {
        padding: 0.75rem;
        vertical-align: top;
    }

    thead th {
        vertical-align: bottom;
    }

    tbody + tbody {
        border-top: 2px solid #dee2e6;
    }

    thead{
        color: ${props => props.theme.colors.text};
        border: 0;
        
        th{
            border: 0;
        }
    }

    td {
        border: 0;
        border-right: 1px solid rgba(255, 255, 255, 0.05);
        color: ${props => props.theme.colors.text};
    }

    ${props => props.striped && css`
        tbody tr:nth-of-type(odd) {
            background-color: ${darken(0.03, props.theme.colors.superimposedBackground)};
        }
    `}
    
    ${props => props.hover && css`
        tbody tr:hover {
            color: #212529;
            background-color: ${darken(0.05, props.theme.colors.superimposedBackground)};
        }
     `}
     
     .fixed-side {
        position: sticky;
        background: rgba(0, 0, 0, 0.06);
    }
`;