import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { Form } from '@unform/web';
import { UseToast } from '../../hooks/ToastContext';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import { 
    FiUser, 
    FiLock, 
    FiGlobe, 
    FiFile,
    FiPercent 
} from 'react-icons/fi';
import api from '../../utils/api';
import { useAuth } from '../../hooks/Auth';

import Card from '../../components/CardContainer';
import Input from '../../components/Input';
import FileInput from '../../components/FileInput';
import Button from '../../components/Button';
import DropDown from '../../components/Dropdown';

import { 
    ColumnTwo, 
    ContainerRegisterLogotipo, 
    ContainerNotImage,
    HelpDomain,
    ContainerDropdown
} from './style';
import ColorPicker from '../../components/ColorPicker';
import Loading from '../../components/Loading';
import { Text } from '@chakra-ui/react';

const Settings = () => {
    const formRef = useRef(null);
    const configFormRef = useRef(null);

    const [credentialsApi, setCredentialsApi] = useState({});
    const [fileLogotipo, setFileLogotipo] = useState(null);
    const [fileLogotipoPrimary, setFileLogotipoPrimary] = useState(null);
    const [isLogotipo, setIsLogotipo] = useState(false);

    const [enterprises, setEnterprises] = useState([]);
    const [enterpriseSelected, setEnterpriseSelected] = useState(null);

    const [settings, setSettings] = useState(null);

    const [loadingUnits, setLoadingUnits] = useState(false);
    const [loading, setLoading] = useState(false);

    const { user } = useAuth();
    const { addToast } = UseToast();
    
    const token = localStorage.getItem('@amoraid:token');

    const config = useMemo(() => ({
        headers: { Authorization: `Bearer ${token}`}
    }), [token]);

    useEffect(() => {
        async function getCredentialsApi()
        {
            try {
                const response = await api.get(`/credentials-api/${user.id}`, config);
                if(response.data) setCredentialsApi(response.data);
            } catch (error) {    
            }
        }
        getCredentialsApi();
        async function getLogotipoPrimary()
        {
            const responseUser = await api.get(`/users/${user.id}`, config);
            
            if(responseUser.data){
                
                if(responseUser.data.file_brand) setFileLogotipoPrimary(process.env.REACT_APP_API_URL + `/files/${responseUser.data.file_brand}`);
            }
        }
        getLogotipoPrimary();

        async function getEnterprises()
        {
            setLoadingUnits(true);
            const response = await api.get(`/enterprises/`, config);

            if(response.data){
                setEnterprises(response.data.enterprises);
            }
            setLoadingUnits(false);
        }

        getEnterprises();

        async function getSettingsUser(){
            const response = await api.get(`/settings/${user.id}`, config);

            if(response.data) {
                configFormRef.current.setData({
                    table_price: response.data.table_price,
                    table_header_color: response.data.table_header_color,
                });
                setSettings(response.data);
            };
        }

        getSettingsUser();

    }, [user, config]);

    const handleCredentialsApi = useCallback(async (data) => {
            
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    domain: Yup.string().required('Dominio é obrigatório.'),
                    user: Yup.string().required('Usuario é obrigatório.'),
                    password: Yup.string().required('Senha é obrigatório.'),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                await api.post('/credentials-api', {
                    user_id: user.id,
                    ...data,
                }, config);
                // console.log(response);
                addToast({
                    type: 'success',
                    title: 'Credencias de api salva com sucesso.',
                });

            } catch (err) {
                
                if(err instanceof Yup.ValidationError){

                    const errors = getValidationErrors(err);
                    formRef.current.setErrors(errors);
                    return;
                }

                addToast({
                    type: 'error',
                    title: 'Erro ao cadastrar credenciais.',
                    description: 'Certifique se os dados estão corretos.',
                });
            }
          
        }, [addToast, user.id, config]);
    
    const handleUploadLogotipoPrimary = useCallback(async (data) => {
        console.log(data, user);
        try {
            const formdata = new FormData();
            formdata.append('file', data.logotipo);

            const response = await api.put(process.env.REACT_APP_API_URL + `/users/${user.id}/brand`, 
            formdata,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  ...config
            });
            
            if(response.data) setFileLogotipoPrimary(process.env.REACT_APP_API_URL + `/files/${response.data.file_brand}`);
           
            addToast({ 
                type: 'success', 
                title: fileLogotipoPrimary ? "Logotipo atualizado com sucesso" : 'Logotipo cadastrado com sucesso.',
            });
            
        } catch (error) {
            addToast({ 
                type: 'error', 
                title: isLogotipo ? "Erro ao atualizar o logotipo" : 'Erro ao cadastrado o logotipo.',
                description: 'Certique-se que a imagem está no formato correto ou tenta mais tarde.'
            });
        }
    }, [config, user, addToast, fileLogotipoPrimary, isLogotipo]);

    const handleUploadLogotipo = useCallback(async (data) => {
        
            try {
                const formdata = new FormData();
                formdata.append('file', data.logotipo);
                formdata.append('enterprise_id', enterpriseSelected.id);

                const response = await api.post(`/enterprises/brand`, formdata, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                      },
                      ...config
                });
        
                if(response.data) setFileLogotipo(process.env.REACT_APP_API_URL + `/files/${response.data.filename}`);
                
                addToast({ 
                    type: 'success', 
                    title: isLogotipo ? "Logotipo atualizado com sucesso" : 'Logotipo cadastrado com sucesso.',
                });
                
            } catch (err) {
                addToast({ 
                    type: 'error', 
                    title: isLogotipo ? "Erro ao atualizar o logotipo" : 'Erro ao cadastrado o logotipo.',
                    description: 'Certique-se que a imagem está no formato correto ou tenta mais tarde.'
                });
            }
        }, [addToast, config, enterpriseSelected, isLogotipo]);

    const handleSelectedValue = useCallback(async (enterprise) => {
        setEnterpriseSelected(enterprise);
        
        try {
            const { id } = enterprise;
            const response = await api.get(`/enterprises/brand/${id}`);
            if(response.data){
                setFileLogotipo(process.env.REACT_APP_API_URL + `/files/${response.data.filename}`);
                setIsLogotipo(true);
            } 
        } catch (error) {
            setFileLogotipo(null);
            setIsLogotipo(false);
        }

    }, [setEnterpriseSelected]);

    const handlePercentExceed = useCallback((data) => {
        let formatNumber = Number(data);
        if(formatNumber > 100) {
            configFormRef.current.setFieldValue('table_price', 100);
        } 
    }, [configFormRef]);

    const handleSubmitConfig = useCallback(async (data) => {
        try {
            setLoading(true);
            data.table_price = Number(data.table_price);
            
            if(!settings) {
                await api.post('/settings', data, config);
                addToast({
                    type: 'success',
                    title: 'Configuração adicionada com sucesso',
                });
                setSettings(data);
                return;
            }

            await api.put(`/settings/${user.id}`, data, config);
            setSettings(data);
            
            addToast({
                type: 'success',
                title: 'Configuração atualizada com sucesso',
            });

        } catch (err) {
            addToast({
                type: 'error',
                title: 'Houve um erro ao cadastrar',
                description: 'Tente novamente mais tarde.'
            });
        } finally { setLoading(false) }
    },[config, addToast, settings, user]);

    return (
        <>
            <Loading isVisabled={loading}/>
            <Text marginBottom={16} size="lg" as="h1">Configurações</Text>
                <Card>
                    <strong>API Sienge</strong>
                    <small style={{marginBottom: 16}}>Certifique que suas credenciais estão corretas antes de salvar, só é permitido apenas uma credencial de api.</small>
                    <Form onSubmit={handleCredentialsApi} initialData={credentialsApi} ref={formRef}> 
                        <ColumnTwo>
                            <div>
                                <Input label="Domínio" id="domain" name="domain" Icon={FiGlobe} overlay onChange={() => {}}/>
                                <HelpDomain><strong>Ex:</strong> https://api.sienge.com.br/<strong>{"{subdomain-do-cliente}"}</strong>/public/api/v1/creditors</HelpDomain>
                            </div>
                            <Input label="Usuário" id="user" name="user" Icon={FiUser} overlay onChange={() => {}}/>
                        </ColumnTwo>
                        <ColumnTwo>
                            <Input label="Senha" type="password" id="password" name="password" Icon={FiLock} overlay onChange={() => {}}/>
                        </ColumnTwo>
                        
                        <Button type="submit" colorStyle="primary" style={{marginTop: 16}}>Salvar</Button>
                            
                    </Form>
                </Card>
            <Card style={{marginTop: 16}}>
                <strong>Cadastro de Logotipos por empreendimentos</strong>
                <small>Cadastre o logotipo que deseja utilizar na hora de exportar seus documentos.</small>
                <small>Certifique-se que as proporções estejam corretas <strong>(200x100 px)</strong>.</small>

                <ContainerRegisterLogotipo>
                    <div>
                            <ContainerNotImage>
                                {fileLogotipo ? (
                                    <img src={fileLogotipo} alt="Logotipo export document"/>
                                ) : (
                                    <span>Nenhum logotipo cadastrado</span>
                                )}
                            </ContainerNotImage>
                    </div>
                    <div>
                            <Form onSubmit={handleUploadLogotipo} style={{width: 350}}>
                                <ContainerDropdown>
                                    <DropDown 
                                        prompt="Procurar empreendimento"
                                        id="enterprises"
                                        label="name"
                                        onChange={handleSelectedValue}
                                        options={enterprises}
                                        loading={loadingUnits}
                                        overlay={true}
                                    />
                                </ContainerDropdown>
                                {!!enterpriseSelected ? (
                                    <FileInput label="Selecionar logotipo" icon={FiFile} overlay id="logotipo" name="logotipo" />
                                ) : ""}
                                <Button colorStyle="primary" type="submit" style={{marginTop: 16}}>{isLogotipo ? "Atualizar" : "Salvar"}</Button>
                            </Form>
                    </div>
                </ContainerRegisterLogotipo>
            </Card>
            <Card style={{marginTop: 16}}>
                <strong>Logotipo Principal</strong>
                <small>Cadastre o logotipo que deseja utilizar na hora de exportar seus documentos.</small>
                <small>Este logotipo fica posicionado no canto direito do documento, certifique-se que as proporções esteja corretas <strong>(200x100 px)</strong>.</small>

                <ContainerRegisterLogotipo>
                    <div>
                            <ContainerNotImage>
                                {fileLogotipoPrimary ? (
                                    <img src={fileLogotipoPrimary} alt="Logotipo export document"/>
                                ) : (
                                    <span>Nenhum logotipo cadastrado</span>
                                )}
                            </ContainerNotImage>
                    </div>
                    <div>
                            <Form onSubmit={handleUploadLogotipoPrimary} style={{width: 350}}>
            
                                <FileInput label="Selecionar logotipo" icon={FiFile} overlay id="logotipo" name="logotipo" />
                                
                                <Button colorStyle="primary" type="submit" style={{marginTop: 16}}>{fileLogotipoPrimary ? "Atualizar" : "Salvar"}</Button>
                            </Form>
                    </div>
                </ContainerRegisterLogotipo>
            </Card>
            <Card style={{marginTop: 16}}>
                <strong>Configurações adicionais</strong>
                <small>Configurações aplicadas para o financiamento de unidades</small>
                <Form onSubmit={handleSubmitConfig} style={{marginTop: 16}} ref={configFormRef}>
                        <ColumnTwo>
                            <div>
                                <Input 
                                    label="Juros price" 
                                    name="table_price" 
                                    id="table_price" 
                                    type="number"
                                    Icon={FiPercent}
                                    step="any"
                                    onChange={handlePercentExceed}
                                    overlay
                                />
                                <HelpDomain >Taxa de juros sobe as parcelas do financiamento.</HelpDomain>
                            </div>
                            <div>
                                <ColorPicker
                                    prompt="Cor da tabela PDF" 
                                    name="table_header_color" 
                                    id="table_header_color" 
                                    overlay 
                                />
                                <HelpDomain>Digite a cor em Hexadecimal.</HelpDomain>
                            </div>
                        </ColumnTwo>
                        <Button type="submit" style={{marginTop: 16}}>
                            {settings ? "Atualizar" : "Salvar"}
                        </Button>
                    </Form>
            </Card>
        </>
    );
}

export default Settings;