import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const ContainerTable = styled.div`
    width: 100%;
    overflow-x: auto;
    margin-top: 16px;
    border-right: 2px solid ${props => darken(0.05, props.theme.colors.superimposedBackground)};

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: ${props => darken(0.05, props.theme.colors.superimposedBackground)};
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: ${props => lighten(0.2, props.theme.colors.superimposedBackground)};
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: ${props => darken(0.05, lighten(0.2, props.theme.colors.superimposedBackground))};
    }
`;


export const Status = styled.div`
    display: block;
    height: 16px;
    width: 16px;
    background: ${props => props.color};
    border-radius: 50%;
    cursor: pointer;
`;