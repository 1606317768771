import React, { useCallback, useRef, useContext } from 'react';
import { useAuth } from '../../hooks/Auth';
import { Link, useHistory } from 'react-router-dom';
import { UseToast } from '../../hooks/ToastContext';
import { ThemeContext } from 'styled-components';
import getValidationErrors from '../../utils/getValidationErrors';
import imageBanner from '../../assets/pixeltrue-time-management.svg';
import logotipo from '../../assets/amora-dark-old.png';
import logotipoLight from '../../assets/amora.id-old.png';
import Input from '../../components/Input';
import { Form } from '@unform/web';
import Button from '../../components/Button';
import { FiMail, FiUser } from 'react-icons/fi';
import * as Yup from 'yup';
import { 
    Wrapper, 
    Background, 
    Content,
    AnimationContainer, 
    CreateAccount,  
} from './style';
import { Heading } from '@chakra-ui/layout';
function SignIn(){
    const formRef = useRef(null);
    const { signIn } = useAuth();
    const { addToast } = UseToast();
    const history = useHistory();
    const { title } = useContext(ThemeContext); 
    // console.log({user});
    const handleSignIn = useCallback(async (data) => {
        // console.log(data);
        
        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                email: Yup.string().email().required("E-mail é obrigatório."),
                password: Yup.string().required("Senha informada não parece válida."),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            await signIn({
                email: data.email,
                password: data.password,
            });
                        
            history.push('/dashboard');

        } catch (err) {
    

            if(err instanceof Yup.ValidationError){

                const errors = getValidationErrors(err);
                formRef.current.setErrors(errors);
                return;
            }

            addToast({
                type: 'error',
                title: 'Erro na autenticação',
                description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
            });
        }

       

    }, [signIn, addToast, history]);
    
    return (
        <Wrapper>
            <Background>
                <div className="brand">
                    <img src={title === "dark" ? logotipo : logotipoLight} alt="amora.id"/>
                    <p>
                       <strong>Simplifique</strong> a visualização de <strong>todos os dados</strong> do seu negócio. Tudo de maneira <strong>intuitiva</strong> para o seu gosto e <strong>compatível</strong> com o quê a sua rotina exige.</p>
                </div>
                <img src={imageBanner} className="image-highlights" alt="Pixeltrue by Pixeltrue"/>
            </Background>
            <Content>
                <AnimationContainer>
                    <Form ref={formRef} onSubmit={handleSignIn}>
                        <Heading as="h1" size="lg">Faça seu login</Heading>
                        <Input id="email" label="E-mail" type="email" name="email" onChange={() => {}} Icon={FiMail} placeholder="name@gmail.com"/>
                        <Input id="password" label="Senha" type="password" name="password"onChange={() => {}} Icon={FiUser} placeholder="Sua senha"/>
                        {/* <a href="#">Esqueci minha senha</a> */}
                        <Button type="submit" colorStyle="primary">Entrar</Button>
                    </Form>
                    <CreateAccount>
                        <Link to="/signup">Criar conta</Link>
                    </CreateAccount>
                </AnimationContainer>
            </Content>
        </Wrapper>
    )
};

export default SignIn;