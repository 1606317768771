import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
    /* height: 56px; */
    width: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 8px 16px;
    border: 2px solid transparent;
    background: ${props => props.theme.colors.superimposedBackground};
    transition: 0.2s ease;

    ${props => props.overlay && css`
        background: ${props => darken(0.02, props.theme.colors.superimposedBackground)};
    `}
    
    span{
        font-size: 13px;
        color: ${props => props.theme.colors.label};
        margin-bottom: 2px;
        transition: 0.2s ease;
    }

    ${props => props.isErrored && css`
        color: ${props => props.theme.colors.danger};
        border-color: ${props => props.theme.colors.danger};
    `}
    
    ${props => props.isFocused && css`
        color: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};

        label{
            color: ${props => props.theme.colors.primary};   
        }
    `}

    ${props => props.isFilled && css`
        label{
            color: ${props => props.theme.colors.primary};   
        }
    `}
`;

export const InputContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;

    label{
        display: flex;
        flex: 1;
        cursor: pointer;
        color: ${props => lighten(0.2, props.theme.colors.label)};
    }
    svg{
        margin-left: 8px;
    }

    input{
        display: none;
    }
`;