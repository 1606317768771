import { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import { Form } from '@unform/web';
import api from '../../utils/api';
import { Link } from 'react-router-dom';
import { UseToast } from '../../hooks/ToastContext';
import { format } from 'date-fns';

import Modal from '../../components/ChakraModal';
import {
    Tabs,
    Tab,
    TabList,
    TabPanels,
    TabPanel,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Box,
    ModalBody,
    ModalFooter,
} from '@chakra-ui/react';

import { ThemeContext } from 'styled-components';

import Headline from '../../components/HeadlinePage';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import Loading from '../../components/Loading';

import { Heading } from '@chakra-ui/layout';

import { 
    ContainerInfoModalOutcome,
    ContainerPaymentsModalOutcome
} from './style';

function EconomicAnalysis()
{
    const [ enterprises, setEnterprises ] = useState([]);
    const [ enterprisesSelected, setEnterprisesSelected ] = useState(null);
    const [costEstimations, setCostEstimations] = useState(null);

    const [outcome, setOutcome] = useState(null);

    const [loadingRequest, setLoadingRequest] = useState(false);
    const { colors } = useContext(ThemeContext);

    const [loadingEnterprises, setLoadingEnterprises] = useState(false);

    const [selectedFinancialData, setSelectedFinancialData] = useState('');
    const [isOpenModalItemOutcome, setIsOpenModalItemOutcome] = useState(false);
    const [itemSelectedForModalOutcome, setItemSelectedForModalOutcome] = useState(null);

    const { addToast } = UseToast();
    const token = localStorage.getItem('@amoraid:token');

    const config = useMemo(() => ({
        headers: { Authorization: `Bearer ${token}`}
    }), [token]);
    
    useEffect(() => {
        async function getEnterprises()
        {
            try {
                setLoadingEnterprises(true);
                const response = await api.get('/enterprises?limit=200', config);
               
                if(response.data) setEnterprises(response.data.enterprises);

            } catch (reason) {
                if(reason.response.status === 401 && reason.response.data.message === "User does not have api access credentials."){
                    addToast({
                        type: 'error',
                        title: 'Você não possui acesso a API',
                        description: <span>Certifique-se de cadastrar as credenciais de acesso ao Api do Sienge, <Link to="/dashboard/settings">clique aqui.</Link></span>
                    });
                }
                if(reason.response.status === 401 && reason.response.data.message === "Invalid authentication credentials"){
                    addToast({
                        type: 'error',
                        title: 'Erro na autenticação de API.',
                        description: <span>Certifique-se que as credenciais cadastrada estão corretas, para atualizar <Link to="/dashboard/settings">clique aqui.</Link></span>
                    });
                }
            }finally{
                setLoadingEnterprises(false);
            }

        }
        getEnterprises();

    }, [addToast, config]);

    const handleSelectedValue = useCallback(async (enterprise) => {
        setEnterprisesSelected(enterprise);
    },[]);

    const handleSubmitForm = useCallback(async (data) => {     
        try {
            setLoadingRequest(true);
            if(!enterprisesSelected){
                addToast({
                    type: 'error',
                    title: 'Selecione um empreendimento'
                });
                return;
            }
            
            // return;
            // buscar orçamento
            const { data } = await api.get(`/building-cost-estimations/${enterprisesSelected.id}`, config);
            setCostEstimations(data);
            
            const responseEnterprise = await api.get(`/enterprises/${enterprisesSelected.id}`, config);
            const enterprise = responseEnterprise.data;
            // buscar titulos
            const response = await api.get(`/outcome`, { 
                params: {
                    costCentersId: enterprisesSelected.id,
                    startDate: enterprise.constructionDetails.startDate,
                    endDate: enterprise.constructionDetails.endDate,
                    selectionType: 'D',
                    correctionIndexerId: 0,
                    correctionDate: enterprise.constructionDetails.startDate
                },
                ...config
            })
            // console.log(response.data.outcome);
            setOutcome(response.data.outcome);
            setSelectedFinancialData('committed');
            // handleFormatPhysicalProgress(costEstimations);
        } catch (error) {
            addToast({
                type: 'error',
                title: 'Houve um erro ao consultar este empreedimento',
                description: 'Verifique no Sienge este empreendimento'
            });
        }finally { setLoadingRequest(false); }
    },[enterprisesSelected, addToast, config]);

    function handleOpenOutcome(id, type) {
        const item = outcome[type].result.find(i => i.billId === id);
        if(item) setItemSelectedForModalOutcome(item);
        setIsOpenModalItemOutcome(true);
    }

    return (
        <>
            <Loading isVisabled={loadingRequest} />
            <Modal 
                isOpen={isOpenModalItemOutcome} 
                onOpen={() => setIsOpenModalItemOutcome(true)}
                onClose={() => setIsOpenModalItemOutcome(false)}
                title={itemSelectedForModalOutcome ? `Documento - #${itemSelectedForModalOutcome.billId}` : "Documento"}
                size="xl"
            >
                <ModalBody>
                    <Tabs>
                        <TabList>
                            <Tab>Informações</Tab>
                            <Tab>Pagamentos</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel p="32px 0px 16px 0px">
                                <ContainerInfoModalOutcome>
                                    {itemSelectedForModalOutcome && (
                                        <>
                                        <div>
                                            <strong>Informações</strong>
                                            <div>
                                                <span>Tipo:</span>
                                                <p>{itemSelectedForModalOutcome.documentIdentificationName}</p>
                                            </div>
                                            <div>
                                                <span>Data de emissão:</span>
                                                <p>{format(new Date(itemSelectedForModalOutcome.issueDate), 'dd/MM/yyyy')}</p>
                                            </div>
                                            <div>
                                                <span>Fornecedor:</span>
                                                <p>{itemSelectedForModalOutcome.creditorName}</p>
                                            </div>
                                            <div>
                                                <span>Data de vencimento:</span>
                                                <p>{format(new Date(itemSelectedForModalOutcome.dueDate), 'dd/MM/yyyy')}</p>
                                            </div>
                                            <div>
                                                <span>Nome do projeto:</span>
                                                <p>{itemSelectedForModalOutcome.projectName}</p>
                                            </div>
                                            <div>
                                                <span>Valor:</span>
                                                <p>{itemSelectedForModalOutcome.originalAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</p>
                                            </div>
                                            {/* Data de vencimento */}
                                        </div>
                                        <div>
                                            <strong>Custo por departamento</strong>
                                            <div>
                                                <span>Tipo:</span>
                                                <p>{itemSelectedForModalOutcome.documentIdentificationName}</p>
                                            </div>
                                           
                                        </div>
                                        </>
                                    )}
                                </ContainerInfoModalOutcome>
                            </TabPanel>
                            <TabPanel p="32px 0px 16px 0px">
                                <ContainerPaymentsModalOutcome>
                                    {itemSelectedForModalOutcome && (
                                        <>
                                            <div>
                                                <strong>Desembolso do titulo</strong>
                                                {itemSelectedForModalOutcome.payments.map(payment => (
                                                    <div>
                                                        <div>
                                                            <span>Tipo da baixa:</span>
                                                            <p>{payment.operationTypeName}</p>
                                                        </div>
                                                        <div>
                                                            <span>Valor do juros:</span>
                                                            <p>{payment.interestAmount}</p>
                                                        </div>
                                                        <div>
                                                            <span>Data de pagamento:</span>
                                                            <p>{format(new Date(payment.paymentDate), 'dd/MM/yyyy')}</p>
                                                        </div>
                                                        <div>
                                                            <span>Valor líquido:</span>
                                                            <p>{payment.netAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                                
                                                {/* Data de vencimento */}
                                            </div>
                                        </>
                                    )}
                                </ContainerPaymentsModalOutcome>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
                <ModalFooter>
                    <Button colorStyle="secondary" onClick={() => setIsOpenModalItemOutcome(false)}>Fechar</Button>
                </ModalFooter>
            </Modal>
            <Headline>
            <div className="description">
                <Heading as="h1" size="lg">Análise de custo</Heading>
                <p>Para consultar a sua obra selecione o filtro ao lado.</p>
            </div>
            <Form onSubmit={handleSubmitForm}>
                <div style={{width: 320}}>
                    <Dropdown 
                        prompt="Buscar empreendimento"
                        id="enterprises"
                        label={['id', 'name']}
                        onChange={handleSelectedValue}
                        options={enterprises}
                        loading={loadingEnterprises}
                    />

                </div>
                
                <Button type="submit">Consultar</Button>
            </Form>
            </Headline> 
            <Tabs d="flex" gridGap="32px" pt="2">
                <TabList d="flex" flexDirection="column" gridGap="8px" w="350px" borderBottom="none">
                    <Box
                        d="flex"
                        flexDirection="column"
                        padding="16px"
                        borderRadius="10px"
                        border="2px solid transparent"
                        alignItems="start"
                        background={colors.superimposedBackground}
                    >
                        <p>Custo orçado</p>
                        {!costEstimations ? (
                            <Box width="100%" height="24px" background="rgba(0, 0, 0, 0.1)" />
                        ) : (
                            <strong style={{ fontWeight: 900, fontSize: 18 }}>
                                {costEstimations.totalCostEstimation.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}
                            </strong>
                        )}
                    </Box>
                    <Tab 
                        _selected={{ color: 'blue.100', borderColor: 'blue.100'}}
                        d="flex"
                        flexDirection="column"
                        padding="16px"
                        borderRadius="10px"
                        border="2px solid transparent"
                        alignItems="start"
                        background={colors.superimposedBackground}
                        onClick={() => setSelectedFinancialData('committed')}
                    >
                        <header>
                            <p>Comprometido</p>
                        </header>
                            {!outcome ? 
                            (
                                <div style={{width: '100%', display: 'flex', alignItems: 'center', gap: 4}}>
                                    <span style={{ fontSize: 18, fontWeight: 900 }}>R$</span>
                                    <span style={{ flex: 1, height: 24, background: 'rgba(0, 0, 0, 0.1)'}} />
                                </div>
                            ) :
                            (
                            <strong style={{ fontWeight: 900, fontSize: 18 }}>
                                {(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'})
                                .format(outcome.committed.total))}
                            </strong>
                            )}                        
                    </Tab>
                    <Tab 
                        _selected={{ color: 'blue.100', borderColor: 'blue.100'}}
                        d="flex"
                        flexDirection="column"
                        padding="16px"
                        borderRadius="10px"
                        border="2px solid transparent"
                        alignItems="start"
                        background={colors.superimposedBackground}
                        onClick={() => setSelectedFinancialData('accomplished')}

                    >
                        <header>
                            <p>Realizado</p>
                        </header>
                        {!outcome ? (
                            <div style={{width: '100%', display: 'flex', alignItems: 'center', gap: 4}}>
                                <span style={{ fontSize: 18, fontWeight: 900 }}>R$</span>
                                <span style={{ flex: 1, height: 24, background: 'rgba(0, 0, 0, 0.1)'}} />
                            </div>
                        ) : (
                            <strong>
                                {(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'})
                                .format(outcome.accomplished.total))}
                            </strong>
                        )}
                    </Tab>
                    <Tab 
                        _selected={{ color: 'blue.100', borderColor: 'blue.100'}}
                        d="flex"
                        flexDirection="column"
                        padding="16px"
                        borderRadius="10px"
                        border="2px solid transparent"
                        alignItems="start"
                        background={colors.superimposedBackground}
                        onClick={() => setSelectedFinancialData('disbursement')}

                    >
                        <header>
                            <p>Desembolso financeiro</p>
                        </header>
                        {!outcome ? (
                            <div style={{width: '100%', display: 'flex', alignItems: 'center', gap: 4}}>
                                <span style={{ fontSize: 18, fontWeight: 900 }}>R$</span>
                                <span style={{ flex: 1, height: 24, background: 'rgba(0, 0, 0, 0.1)'}} />
                            </div>
                        ) : (
                            <strong>- {(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'})
                            .format(outcome.disbursement.total))}</strong>
                        )}
                    </Tab>
                    <Box
                        d="flex"
                        flexDirection="column"
                        padding="16px"
                        borderRadius="10px"
                        border="2px solid transparent"
                        alignItems="start"
                        background={
                            ((costEstimations && outcome) && costEstimations.totalCostEstimation - outcome.committed.total) < 0 ? 
                            `linear-gradient(45deg, ${colors.danger}, #FF0080)`
                            : `linear-gradient(45deg, ${colors.success}, ${colors.primary})`}
                    >
                        <p>Disponível</p>
                        {(outcome && costEstimations) ? (
                            <strong>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'})
                                .format((costEstimations.totalCostEstimation - outcome.committed.total)))}</strong>
                        ) : (
                            <div style={{width: '100%', display: 'flex', alignItems: 'center', gap: 4}}>
                                <span style={{ fontSize: 18, fontWeight: 900 }}>R$</span>
                                <span style={{ flex: 1, height: 24, background: 'rgba(255, 255, 255, 0.1)'}} />
                            </div>
                        )}

                    </Box>
                </TabList>
                <TabPanels>
                    <TabPanel 
                        flex="1"
                        maxHeight={492}
                        overflowY="auto" 
                    >
                        <div>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>N Titulo</Th>
                                        <Th>Tipo</Th>
                                        <Th>Fornecedor</Th>
                                        <Th>Data de emissão</Th>
                                        <Th>Valor</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                {(selectedFinancialData && outcome) && (
                                    outcome['committed'].result.map(item => (
                                        <Tr cursor="pointer" 
                                            _hover={{
                                                filter: 'brightness(0.9)'
                                            }} 
                                            key={`${item.billId}-${item.originalAmount}`}
                                            onClick={() => handleOpenOutcome(item.billId, 'committed')}>
                                            <Td>{item.billId}</Td>
                                            <Td>{item.documentIdentificationName}</Td>
                                            <Td>{item.creditorName}</Td>
                                            <Td>{format(new Date(item.issueDate), 'dd/MM/yyyy')}</Td>
                                            <Td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'})
                                                .format(item.originalAmount))}</Td>
                                        </Tr>
                                    ))
                                )}
                                </Tbody>
                            </Table>
                        </div>
                    </TabPanel>
                    <TabPanel
                        flex="1"
                        maxHeight={492}
                        overflowY="auto" 
                    >
                    <div>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>N Titulo</Th>
                                        <Th>Tipo</Th>
                                        <Th>Fornecedor</Th>
                                        <Th>Data de emissão</Th>
                                        <Th>Valor</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                {(selectedFinancialData && outcome) && (
                                    outcome['accomplished'].result.map(item => (
                                        <Tr 
                                            cursor="pointer" 
                                            key={`${item.billId}-${item.originalAmount}`}
                                            onClick={() => handleOpenOutcome(item.billId, 'accomplished')}
                                        >
                                            <Td>{item.billId}</Td>
                                            <Td>{item.documentIdentificationName}</Td>
                                            <Td>{item.creditorName}</Td>
                                            <Td>{format(new Date(item.issueDate), 'dd/MM/yyyy')}</Td>
                                            <Td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'})
                                                .format(item.originalAmount))}</Td>
                                        </Tr>
                                    ))
                                )}
                                </Tbody>
                            </Table>
                        </div>
                    </TabPanel>
                    <TabPanel
                        flex="1"
                        maxHeight={492}
                        overflowY="auto" 
                    >
                    <div>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>N Titulo</Th>
                                        <Th>Tipo</Th>
                                        <Th>Fornecedor</Th>
                                        <Th>Data de emissão</Th>
                                        <Th>Valor</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                {(selectedFinancialData && outcome) && (
                                    outcome['disbursement'].result.map(item => (
                                        <Tr 
                                            cursor="pointer" 
                                            key={`${item.billId}-${item.originalAmount}`}
                                            onClick={() => handleOpenOutcome(item.billId, 'disbursement')}>
                                            <Td>{item.billId}</Td>
                                            <Td>{item.documentIdentificationName}</Td>
                                            <Td>{item.creditorName}</Td>
                                            <Td>{format(new Date(item.issueDate), 'dd/MM/yyyy')}</Td>
                                            <Td>{(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'})
                                                .format(item.originalAmount))}</Td>
                                        </Tr>
                                    ))
                                )}
                                </Tbody>
                            </Table>
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>
                       
        </>
    );
}
export default EconomicAnalysis;