import { useContext } from 'react';

import Table from '../../../components/TableBootstrap';
import { ThemeContext } from 'styled-components';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { shade, lighten } from 'polished';
import Tooltip from '../../../components/Tooltips';

import { 
    ContainerTable,
    Status 
} from './style';

const status = {
    c: {
        title: "Reservada",
        color: "#f77f00",
        },
    d: {
        title: "Disponível",
        color: "#5ee2a0",
        },
    r: {
        title: "Reserva técnica",
        color: "#219ebc",
        },
    e: {
        title: "Permuta",
        color: "#eae2b7",
        },
    m: {
        title: "Mútuo",
        color: "#ffffff",
        },
    p: {
        title: "Proposta",
        color: "#3B86FF",
        },
    v: {
        title: "Vendida",
        color: "#ff6565",
        },
    l: {
        title: "Locado",
        color: "#fcbf49",
        },
    t: {
        title: "Transferido",
        color: "#adb5bd",
        },
    default: {
        color: "#eaeaea"
    }
}

const TableUnits = ({units, headers, loadingTable}) => {

    const { colors } = useContext(ThemeContext);


    return (
        <>            
            <ContainerTable>
                    {loadingTable ? (
                        <SkeletonTheme 
                        color={shade(0.05, colors.superimposedBackground)} 
                        highlightColor={lighten(0.05, colors.superimposedBackground)}>
                            <Table>
                                <thead>
                                    <tr>
                                        {headers.map(header => <th>{header}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: 5}).map(index => (
                                        <tr key={index}>
                                            {Array.from({ length: 21}).map(index => (
                                                <td key={index}><Skeleton /></td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </SkeletonTheme>
                    ) : (
                        <Table striped hover>
                            <thead>
                                <tr>
                                    {headers.map((header, index) => <th key={index}>{header}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {units && units.map(item => (
                                    <tr key={item.id}>
                                        <td>
                                            {
                                            status[item.commercialStock.toLowerCase()] 
                                            ?  
                                            (
                                                <Tooltip side="right" title={status[item.commercialStock.toLowerCase()].title}>
                                                    <Status color={status[item.commercialStock.toLowerCase()].color}/>
                                                </Tooltip>
                                            )
                                            : (
                                                <Tooltip side="right" title={item.commercialStock.toLowerCase()}>
                                                    <Status color={status.default.color}/>
                                                </Tooltip>
                                            ) }
                                        </td>
                                        <td className="fixed-side" style={{ fontSize: 12 }}>{item.propertyType}</td>
                                        <td className="fixed-side">{item.qd}</td>
                                        <td className="fixed-side">{item.lt}</td>
                                        <td className="fixed-side">{item.privateArea}</td>
                                        <td className="fixed-side">{item.indexedQuantity}</td>
                                        <td className="fixed-side">{item.amount}</td>
                                        <td>{item.m12}</td>
                                        <td>{item.m24}</td>
                                        <td>{item.m36}</td>
                                        <td>{item.m48}</td>
                                        <td>{item.m60}</td>
                                        <td>{item.m72}</td>
                                        <td>{item.m84}</td>
                                        <td>{item.m96}</td> 
                                        <td>{item.m108}</td>
                                        <td>{item.m120}</td>
                                        <td>{item.m132}</td>
                                        <td>{item.m144}</td>
                                        <td>{item.m156}</td>
                                        <td>{item.m168}</td>
                                        <td>{item.m180}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
            </ContainerTable>
        </>
    );
};

export default TableUnits;