import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const ContainerTable = styled.div`
    width: 100%;
    overflow-x: auto;
    margin-top: 16px;
    border-right: 2px solid ${props => darken(0.05, props.theme.colors.superimposedBackground)};

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: ${props => darken(0.05, props.theme.colors.superimposedBackground)};
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: ${props => lighten(0.2, props.theme.colors.superimposedBackground)};
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: ${props => darken(0.05, lighten(0.2, props.theme.colors.superimposedBackground))};
    }
`;


export const ActionsForTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    strong span {
        color: ${props => props.theme.colors.primary};
    }

    > div{
        display: flex;
    }
    form {
        display: flex;

        div small {
            font-size: 12px;
            color: ${props => props.theme.colors.label};
            margin-left: 5px;
        }

        div + button {
            margin-left: 16px;
        }

        & + div {
            margin-left: 16px;
            width: 100px;
        }
    }
`;

export const ContainerFilterTopHeader = styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        
        .filter{ 
            display: flex;
            width: 720px;

            div {
                max-width: 200px;
            } 

            div + div {
                margin-left: 16px;
            }

            > div:last-child {
                width: 100%;
            }
        }

        small {
            color: ${props => props.theme.colors.label};
            
            strong{
                color: ${props => darken(0.5, props.theme.colors.text)};
            }
        }
`;

export const ContainerContentModal = styled.div`

    > strong {
        display: block;
        margin-bottom: .5rem;
    }

    .table-price {
        strong {
            margin-bottom: 1rem;
        }
    }
`;