import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{
        margin: 0px;
        padding: 0px;
        outline: 0px;
        box-sizing: border-box;
    } 
    
    body{
        background: ${props => props.theme.colors.background} !important;
        color: ${props => props.theme.colors.text} !important;
    }

    body, input, textarea, label, button{
        font-family: 'Source Sans Pro', sans-serif !important;
    }

    button{
        cursor: pointer;
    }

    h1, h2, h3, h4, h5, h6{
        font-weight: 900;
    }
`;