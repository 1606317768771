import React, { createContext, useState, useContext, useCallback } from 'react';
import AlertContainer from '../components/AlertContainer';
import { v4 as uuid } from 'uuid'
const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);

    const addAlert = useCallback(({type, title, description}) => {
        const id = uuid();
        const alert = {
            id,
            type,
            title,
            description,
        }

        setMessages(prev => [...prev, alert]);
    }, []);

    const removeAlert = useCallback((id) => {
        setMessages(prev => prev.filter(message => message.id !== id));
    }, []);
    
    return (
        <AlertContext.Provider value={{ addAlert, removeAlert }}>
            {children}
            <AlertContainer messages={messages} />
        </AlertContext.Provider>
    );
};

export const useAlert = () => {
    const context = useContext(AlertContext);

    if(!context) throw new Error('useAlert must be used within a AlertProvider');

    return {
        addAlert: context.addAlert,
        removeAlert: context.removeAlert,
    };
}