import { darken } from 'polished';
import styled from 'styled-components';

export const Row = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 100px;
    gap: 2rem 1rem;
    margin: 32px 0;
    
`;

export const ContainerCharts = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 16px;
`;

export const ContainerPhysicalProgress = styled.div`
    display: flex;
    gap: 32px;

    .box-chart-pie {
        width: 350px;
        background: ${props => props.theme.colors.superimposedBackground};
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        header {
            background: ${props => darken(0.010, props.theme.colors.superimposedBackground)};
            padding: 16px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            p {
                font-size: 18px;
            }

            strong {
                font-size: 20px;
                font-weight: 900;
            }
        }

        footer {
            background: ${props => darken(0.010, props.theme.colors.superimposedBackground)};
            padding: 16px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            text-align: center;
            
            strong {
                font-size: 20px;
                font-weight: 900;
            }
        }

    }

    .box-chart-area {
        flex: 1;

        p {
            font-size: 14px;
            margin-bottom: 16px;
        }
    }
`;

export const ContainerFinance = styled.div`
    display: flex;
    gap: 32px;
    margin-top: 2rem;

    .group-summary {
        width: 350px;

        button, div {
            display: flex;
            width: 100%;
            flex-direction: column;
            background: ${props => props.theme.colors.superimposedBackground};
            padding: 16px;
            border-radius: 10px;
            border: 2px solid transparent;
            transition: all .5s;

            &.highlight-box {
                background: linear-gradient(45deg, 
                ${props => props.theme.colors.success}, 
                ${props => props.theme.colors.primary});

                &.danger {
                    background: linear-gradient(45deg, 
                    ${props => props.theme.colors.danger}, 
                    #FF0080);
                }
            }

            &:hover {
                filter: brightness(0.9);
            }
            &.active {
                border-color: ${props => props.theme.colors.primary};
            }
            strong {
                display: block;
                font-weight: 900;
                font-size: 24px;

            }

            & + div {
                margin-top: 8px;
            }
            
            & + button {
                margin-top: 8px;
            }
        }
    }

    .content-finance {
        flex: 1;
        max-height: 492px;
        overflow-y: auto;
        overflow-x: hidden;
        
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */

        
        strong {
            display: block;
            font-weight: 900;
            font-size: 18px;
            margin: 32px 0 16px;
        }
    }
`;

export const ContainerInfoModalOutcome = styled.div`
    width: 100%;

    > div {
        display: grid;
        position: relative;
        grid-template-columns: 2fr 1fr;
        gap: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 1rem;
        border-radius: 4px;

        & + div {
            margin-top: 1rem;
        }

        > strong {
            display: block;
            position: absolute;
            top: -12px;
            left: 16px;
            padding: 0 8px;
            background: ${props => props.theme.colors.superimposedBackground};
        }

        > div {
            span {
                font-size: 12px;
                color: ${props => props.theme.colors.label};
            }
        }
    }
`;

export const ContainerPaymentsModalOutcome = styled.div`
    > div {
        display: grid;
        position: relative;
        grid-template-columns: 1fr;
        gap: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding: 2rem 1rem;
        
        > strong {
            display: block;
            position: absolute;
            top: -12px;
            left: 16px;
            padding: 0 8px;
            background: ${props => props.theme.colors.superimposedBackground};
        }

        & + div {
            margin-top: 1rem;
        }

        > div {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 1rem;
            border-radius: 10px;
            border: 1px solid ${props => props.theme.colors.background};
            padding: 1rem;

            span {
                font-size: 12px;
                color: ${props => props.theme.colors.label};
            }
        }
    }
`;