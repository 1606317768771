import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const status = {
    c: "Reservada",
    d: "Disponível",
    r: "Reserva técnica",
    e: "Permuta",
    m: "Mútuo",
    p: "Proposta",
    v: "Vendida",
    l: "Locado",
    t: "Transferido",
}
function s2ab(s){
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

function format(data){
    var result = [];
    data.forEach(item => {
        result.push({
            status: status[item.commercialStock] ? status[item.commercialStock] : item.commercialStock,
            qd: item.qd,
            lt: item.lt,
            area_m2: item.privateArea,
            m2_real: item.m2Real,
            entrada: item.inputValueReal,
            aVista: item.indexedQuantity,
            f: item.amount,
            m12: item.m12,  
            m24: item.m24,
            m36: item.m36,
            m48: item.m48,
            m60: item.m60, 
            m72: item.m72, 
            m84: item.m84, 
            m96: item.m96, 
            m108: item.m108,
            m120: item.m120,
            m132: item.m132,
            m144: item.m144,
            m156: item.m156,
            m168: item.m168,
            m180: item.m180,
        });
    });
    return result;
}

function exportUnitsXLSX({data, bookType = 'xlsx', filename = 'amoraid-export'}){

    const dataFormat = format(data);

    /** make the worksheet */
    var worksheet = XLSX.utils.json_to_sheet(dataFormat);

    /** add to workbook */
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Units");

    /** write workbook (use type 'binary') */

    var wbout = XLSX.write(workbook, { bookType, type: 'binary'});

    /** generate download */
    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream'}), `${filename}.${bookType}`);
   
}

export default exportUnitsXLSX;