import React from 'react';
import { Container } from './style';

const Tooltips = ({children, title, side}) => {
    return (
        <Container side={side}>
            {children}
            <span>{title}</span>
        </Container>
    );
}

export default Tooltips;