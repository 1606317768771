import styled, { css, keyframes } from 'styled-components';
import { darken, lighten } from 'polished';

const animate = keyframes`
    0%{
        transform: translateX(0) scaleX(0);
        transform-origin: left;
        left: 5px;
        right: auto;
    }

    45%{
        transform: translateX(0) scaleX(1);
        transform-origin: left;
        left: 5px;
        right: auto;
    }

    50%{
        transform: translateX(0) scaleX(1);
        transform-origin: right;
        left: auto;
        right: 5px;
    }

    100%{
        transform: translateX(0) scaleX(0);
        transform-origin: right;
        left: auto;
        right: 5px;
    }
`;

export const Container = styled.div`
    width: 100%;
    position: relative;

    ul{
        width: 100%;
        list-style: none;
        display: none;
        background-color: ${props => props.theme.colors.superimposedBackground};
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);

        box-sizing: border-box;
        margin-top: 4px;
        max-height: 200px;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1000;
        -webkit-overflow-scrolling: touch;
        transition: 0.2s ease;
        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: ${props => darken(0.05, props.theme.colors.superimposedBackground)};
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: ${props => lighten(0.2, props.theme.colors.superimposedBackground)};
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: ${props => darken(0.05, lighten(0.2, props.theme.colors.superimposedBackground))};
        }

        li{
            box-sizing: border-box;
            color: ${props => props.theme.colors.text};
            cursor: pointer;
            display: block;
            padding: 8px 10px;
            transition: background 0.2s ease;    
            
            &:hover{
                background-color: ${props => darken(0.02, props.theme.colors.superimposedBackground)};
            }
        }

        li.selected{
            background-color: ${props => darken(0.02, props.theme.colors.superimposedBackground)};
        }
    }

    ${props => props.openDropdown && css`
        ul{
            display: block;
        }
    `}

    ${props => props.loading && css`
        &::after{
            content: '';
            position: absolute;
            display: block;
            bottom: -1px;
            left: 5px;
            width: calc(100% - 10px);
            height: 1px;
            background: ${props => props.theme.colors.primary};
            z-index: 2;
            transform-origin: left;
            transform: scaleX(0);
            animation: ${animate} 2s infinite alternate;
        }
    `}
`;

export const Input = styled.div`
    height: 56px;
    width: 100%;
    /* max-width: 200px; */
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 8px 16px;
    border: 2px solid transparent;
    background: ${props => props.theme.colors.superimposedBackground};
    transition: 0.2s ease;

    ${props => props.overlay && css`
        background: ${props => darken(0.02, props.theme.colors.superimposedBackground)};
    `}
    
    label{
        font-size: 0.75rem;
        color: ${props => props.theme.colors.label};
        margin-bottom: 2px;
        transition: 0.2s ease;
    }


    .selected-value{
        display: flex;
        align-items: center;
        
        input{
            flex: 1;
            border: none;
            background: transparent;
            color: ${props => props.theme.colors.text};
            font-size: 1rem;
            font-weight: 500;
        }

    }

    ${props => props.isFocused && css`
        color: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};

        label{
            color: ${props => props.theme.colors.primary};   
        }
    `}

    ${props => props.isFilled && css`
        label{
            color: ${props => props.theme.colors.primary};   
        }
    `}
`;