import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
    display: none;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: ${props => transparentize(0.5, props.theme.colors.background)};
    /* display: flex; */
    justify-content: center;
    align-items: center;

    ${props => props.isVisabled && css`
        display: flex;
    `}
`;