import styled, { css } from 'styled-components';
import { shade,darken } from 'polished';

export const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    /* background:  */
    display: flex;
    position: relative;
`;

export const Sidebar = styled.div`
    width: 250px;
    height: 100%;
    background: ${props => props.theme.colors.superimposedBackground};
    position: fixed;
    z-index: 10;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s linear;

    > button {
        background: none;
        border: 0;
    }

    nav{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        padding-top: 32px;
        margin-bottom: 0;
        list-style: none;
        width: 100%;

        ul{
            list-style: none;
            width: 100%;
            
            li{
                width: 100%;
                display: block;

                a{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 1rem;
                    text-decoration: none;
                    color: ${props => props.theme.colors.text};
                    fill: ${props => props.theme.colors.text};
                    transition: all 0.2s ease-in-out;
                    border-right: 3px solid transparent;

                    svg{
                        margin-right: 8px;
                    }

                    &:hover{
                        /* background: ${shade(0.10, '#fff')}; */
                        color: ${props => props.theme.colors.primary};
                        border-right: 3px solid ${props => props.theme.colors.primary};
                    }
                }

                button{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 1rem;
                    text-decoration: none;
                    color: ${props => props.theme.colors.text};
                    fill: ${props => props.theme.colors.text};
                    transition: all 0.2s ease-in-out;
                    border-right: 3px solid transparent;
                    background: transparent;
                    border: 0;
                    font-size: 16px;
                    
                    svg{
                        margin-right: 8px;
                    }

                    &:hover{
                        /* background: ${shade(0.10, '#fff')}; */
                        color: ${props => props.theme.colors.primary};
                        border-right: 3px solid ${props => props.theme.colors.primary};
                    }
                }
            }
        }
    }

    ${props => props.toggleSidebar && css`
        width: 100px;

        nav ul li a span,
        nav ul li button span{
            display: none;
        }
        nav ul li a,
        nav ul li button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `}
`;

export const NavBrand = styled.img`
    height: 42px;
`;

export const Content = styled.div`
    width: calc(100% - 250px);
    height: 100%;
    position: relative;
    left: 250px;
    transition: .2s linear;
    
    .content {
        width: 100%;
        height: 100%;
        padding: 32px;
    }

    ${props => props.toggleSidebar && css`
        width: calc(100% - 100px);
        left: 100px;
    `}
`;

export const Header = styled.header`
    width: 100%;
    height: 64px;
    background: ${props => props.theme.colors.superimposedBackground};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;

    .info-right{
        display: flex;
        justify-content: center;
        align-items: center;
        
        > label{
            background: ${props => darken(0.05, props.theme.colors.superimposedBackground)};
            margin-right: 16px;
            padding: 8px;
            border-radius: 5px;
            transition: background 0.2s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover{
                background: ${props => darken(0.2, props.theme.colors.superimposedBackground)};
            }

            & + input {
                display: none;
            }
        }



        img{
            height: 42px;
            width: 42px;
            border-radius: 50%;
    
        }
    
        p{
            color: ${props => props.theme.colors.text};
            margin-left: 16px;
            text-decoration: none;
            font-size: 14px;
            font-weight: 400;
        }
    }
`;
