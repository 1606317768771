import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { Container } from './style';

function Loading({isVisabled})
{
    const container = useRef(null);

   useEffect(() => {
    lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('./data.json')
    })
   }, []);
    
   return (
        <Container isVisabled={isVisabled}>
            <div className="animation" ref={container}></div>
        </Container>
    )
}

export default Loading;