
const darkmode = {
    title: 'dark',

    colors: {
        primary: '#3B86FF',
        secondary: '#F0F0F7',
        danger: '#ff6565',
        success: '#5ee2a0',

        background: '#212529',
        superimposedBackground: '#2B2F33',
        text: '#fff',
        label: '#666360',
    }
};

export default darkmode;