const ligthmode = {
    title: 'light',
    colors: {
        primary: '#3B86FF',
        secondary: '#F0F0F7',
        danger: '#d5211d',
        success: '#5ee2a0',
        
        background: '#f5f6f7',
        backgroundSidebar: '#fff',
        superimposedBackground: '#fff',
        // background
        text: '#284053',

        label: '#C1BCCC',
    }
};

export default ligthmode;