import React, { useContext, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/Auth';

import avatarImage from '../../assets/steve-jobs.png';
import { useTheme } from '../../hooks/ToggleThemeContext';
import logotipoLight from '../../assets/amora.id-old.png';
import logotipoDark from '../../assets/amora-dark-old.png';
import { ThemeContext } from 'styled-components';
import { 
    FiMenu,
    FiHome,
    FiDollarSign,
    FiDivide,
    FiMoon,
    FiSun,
    FiSettings,
    FiLogOut,
    FiX
} from 'react-icons/fi';

import { 
    Wrapper, 
    Header, 
    Sidebar, 
    Content,
    NavBrand,
} from './style';

function Dashboard({children})
{
    const { toggleTheme } = useTheme();
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const { colors, title } = useContext(ThemeContext);
    const { signOut, user } = useAuth();
    const history = useHistory();

    const handleLogOut = useCallback((e) => {
        e.preventDefault();
        signOut();
        history.push('/');
    }, [signOut, history]);

    const handleToggleSidebar = useCallback(() => {
        setToggleSidebar(prev => !prev);
    }, []);

    return (
        <Wrapper> 
            <Sidebar toggleSidebar={toggleSidebar}>
                <button onClick={handleToggleSidebar}>
                    {toggleSidebar ? (<FiMenu size={24} color={colors.text} />) : (<FiX size={24} color={colors.text}/>)}
                </button>
                <nav>
                    <ul>
                        <li>
                            <Link to="/dashboard">
                                <FiHome size={18} />
                                <span>Home</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard/economicanalysis" >
                                <FiDollarSign size={18} />
                                <span>Análise econômica</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard/installmentsunits">
                                <FiDivide size={18}/>
                                <span>Financiamento de unidades</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/dashboard/settings">
                                <FiSettings size={18}/>
                                <span>Configurações</span>
                            </Link>
                        </li>
                        <li>
                            <button onClick={handleLogOut}>
                                <FiLogOut size={18}/>
                                <span>Sair</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </Sidebar>
            <Content toggleSidebar={toggleSidebar}>
                <Header>
                    { title === 'light' ? <NavBrand src={logotipoLight}/> : <NavBrand src={logotipoDark}/> }
                    
                    <div className="info-right">
                        <label for="themeChange">
                            { title === 'light' ? <FiMoon size={18} /> : <FiSun size={18} color="#ffcc00" />}
                            
                        </label>
                        <input 
                        type="checkbox" 
                        id="themeChange" 
                        onChange={toggleTheme}
                        checked={title === "dark"} />
                    
                        <img src={avatarImage} alt="" />
                        <p href="#">{`${user.first_name}`}</p>
                    </div>
                </Header>
                <div className="content">
                    {children}
                </div>
            </Content>
        </Wrapper>
    );
}

export default Dashboard;