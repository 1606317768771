import styled, { css }  from 'styled-components';
import { animated } from 'react-spring';
import { darken, lighten } from 'polished';


const toastTypeVariations = {
    info: css`
        background: ${props => lighten(0.2, props.theme.colors.primary)};
        color: ${props => darken(0.2, props.theme.colors.primary)};
    `,
    success: css`
            background: ${props => lighten(0.2, props.theme.colors.success)};
            color: ${props => darken(0.4, props.theme.colors.success)};
        `,
    error: css`
            background: ${props => lighten(0.2, props.theme.colors.danger)};
            color: ${props => darken(0.4, props.theme.colors.danger)};
        `,  
}

export const Container = styled(animated.div)`
    width: 360px;
    position: relative;
    padding: 16px 30px 16px 16px;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

    display: flex;

    & + div {
        margin-top: 8px;
    }

    ${(props) => toastTypeVariations[props.type || 'info']}
    
    > svg{
        margin: 4px 12px 0 0;
    }

    div{
        flex: 1;

        p{
            margin-top: 4px;
            font-size: 14px;
            opacity: 0.8;
            list-style: 20px;
        }
    }

    button{
        position: absolute;
        right: 16px;
        top: 19px;
        border: 0;
        background: transparent;
        color: inherit;
    }

    ${props => !props.hasDescription && css`
        align-items: center;

        svg{
            margin-top: 0;
        }
    `}
`;