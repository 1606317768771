import { useCallback, useState, useRef, useEffect, useContext } from 'react';
import { useField } from '@unform/core';
import { ThemeContext } from 'styled-components';
import { Container, InputContainer, BoxPreview } from './style';

const ColorPicker = ({prompt, name, id, onChange = null, overlay, icon: Icon, ...rest}) => {
    const containerRef = useRef(null);
    const { colors } = useContext(ThemeContext);
    const [color, setColor] = useState(colors.primary);

    const inputRef = useRef(null);
    const { fieldName, defaultValue = colors.primary, registerField, error } = useField(name);

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const  handleInputBlur = useCallback(() =>{
        setIsFocused(false);

        // transformando em uma condição super curta e transformando o resultado em boolean
        setIsFilled(!!inputRef.current?.value);
    }, []);

    const handleInputOnChange = useCallback((e) => {
        setColor(e.target.value);
    }, []);
    
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref, value){
                if(ref.id === 'table_header_color') setColor(value);
            }
        });
        
    }, [fieldName, registerField]);

    return (
        <Container isFocused={isFocused} isFilled={isFilled} overlay={overlay} isErrored={!!error}>
            <label htmlFor={id}>{prompt}</label>
            <InputContainer>
                <input 
                    ref={inputRef}
                    defaultValue={defaultValue}
                    id={id} 
                    name={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    {...rest}
                    onChange={handleInputOnChange}
                />
                <BoxPreview ref={containerRef} color={color} />
            </InputContainer>
        </Container>
    );
}

export default ColorPicker;