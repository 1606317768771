import styled, { css } from 'styled-components';

const positionTooltips = {
    left: null,
    top: css`
        span{
            bottom: calc(100% + 12px);
            left: 50%;
            transform: translateX(-50%);
        }
    `,
    right: css`
        span{
            left: 100%;
            bottom: calc(100% + 12px);
            border-radius: 4px 4px 4px 0px;

            /* top: 50%; */
            /* transform: translateY(-50%); */
            &::before{
                bottom: 0;
                left: 6px;
                transform: translateX(-50%);
                top: 100%;
            }
        }
    `,
}
export const Container = styled.span`
    position: relative;

    
    span{
        min-width: 160px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);
        background: #000;
        color: #fff;
        padding: 8px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s;
        text-align: center;
        
        position: absolute;
        /* bottom: calc(100% + 12px); */
        /* left: 50%; */
        /* transform: translateX(-50%); */

        
        &::before{
            content: '';
            border-style: solid;
            border-color: #000 transparent;
            border-width: 6px 6px 0 6px;
            bottom: 20px;
            position: absolute;

            left: 50%;
            transform: translateX(-50%);
            top: 100%;
        }
    }

    ${props => positionTooltips[props.side]}

    &:hover span{
        opacity: 1;
        visibility: visible;
    }
`;