class FormatDataForTable {
    execute(data, headers = null, numberOfInstallments = null) {
               
        let newHeaders = headers.filter(header => {
            if(!header.value) return null;
            return header.abbr;
        }).map(header => header.abbr);
      
        let installments;

        if(numberOfInstallments.value){
            installments = this.filterInstallments(data[0], numberOfInstallments.value);
        }else {
            installments = [];
        }
        
        newHeaders = [ ...newHeaders, ...installments.map(installment => installment.abbr )];
        headers = [...headers, ...installments];
        
        var results = data.map(item => {
            let newData = [];

            headers.forEach(header => {
                if(!item[header.value]) return;
                newData.push(item[header.value]);
            });
            
            return newData;
        });

        return {data: results, headers: newHeaders };
    }

    filterInstallments(itemOfGetHeaders, numberInstallments) {
        const headers = Object.keys(itemOfGetHeaders);
       
        const filterHeaderInstallments = headers.filter(item => {
            let match = item.match(/m\d+$/);
            if(!match) return false;
            let number = (match[0].split('m'))[1];
            return number <= numberInstallments;
        });

        return filterHeaderInstallments.map(item => ({value: item, abbr: item}));
    }
}

export default FormatDataForTable;