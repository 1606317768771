import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import { animated } from 'react-spring';


const toastTypeVariations = {
    info: css`
        background: ${props => lighten(0.2, props.theme.colors.primary)};
        color: ${props => darken(0.2, props.theme.colors.primary)};
    `,
    success: css`
            background: ${props => lighten(0.2, props.theme.colors.success)};
            color: ${props => darken(0.4, props.theme.colors.success)};
        `,
    error: css`
            background: ${props => lighten(0.2, props.theme.colors.danger)};
            color: ${props => darken(0.4, props.theme.colors.danger)};
        `,  
}

export const Container = styled(animated.div)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    padding: 0 32px;
    ${(props) => toastTypeVariations[props.type || 'info']}

    .info{
        display: flex;
        align-items: center;

        .description{
            margin-left: 16px;
            
            p{
                font-size: 14px;
                opacity: 0.8;
                list-style: 20px;
            }
        }
    }

    button {
        border: 0;
        background: transparent;
        color: inherit;
    }
`;