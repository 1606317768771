import { useCallback, useState, useRef, useEffect, useContext } from 'react';
import { useField } from '@unform/core';
import { ThemeContext } from 'styled-components';
import { Container, InputContainer } from './style';
import ToolTip from '../../components/Tooltips';

function Input({label, name, id, onChange = null, overlay, Icon, ...rest})
{
    const { colors } = useContext(ThemeContext);

    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const  handleInputBlur = useCallback(() =>{
        setIsFocused(false);

        // transformando em uma condição super curta e transformando o resultado em boolean
        setIsFilled(!!inputRef.current?.value);
    }, []);

    const handleInputOnChange = useCallback((e) => onChange(e.target.value), [onChange]);
    
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [fieldName, registerField]);
    
    return (
        <>
        <Container 
            isFocused={isFocused} 
            isFilled={isFilled} 
            overlay={overlay} 
            isErrored={!!error}
        >
            <label htmlFor={id}>{label}</label>
            <InputContainer>
                <input 
                    ref={inputRef}
                    defaultValue={defaultValue}
                    id={id} 
                    name={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    {...rest}
                    onChange={(e) => handleInputOnChange(e)}
                />
                {Icon && (
                    !!error ? (
                        <ToolTip title={error}>
                            <Icon size={17} color={colors.danger} />
                        </ToolTip>
                    )
                    : <Icon size={17} color={colors.label} />
                )}
            </InputContainer>
        </Container>
        </>
    );
}

export default Input;