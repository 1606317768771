import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import Button from '../../components/Button';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: stretch;
`;

export const Background = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.superimposedBackground};

    > img {
        width: 80%;
    }

    > div {
        width: 100%;
        max-width: 500px;
        margin-bottom: 32px;
        
        img{
            height: 48px;
            margin-bottom: 16px;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 700px;
    background: ${props => props.theme.colors.background};

    form{
        width: 400px;

        h1 {
            margin-bottom: 24px;
        }

        div + div{
            margin-top: 16px;
        }

        button {
            margin-top: 16px;
            width: 100%;
        }
        p{
            margin-bottom: 16px;
        }
        a{
            text-decoration: none;
            color: ${props => props.theme.colors.primary};
            /* float: right; */
            margin: 16px 0;

            &:hover{
                color: ${props => shade(0.2, props.theme.colors.primary)};
            }
        }
    }
`;

export const CreateAccount = styled.div`
    width: 400px;
`;

export const ButtonCreateAccount = styled(Button)`
    margin-top: 16px;
    width: 100%;
    background: transparent;
    border: 2px solid ${props => props.theme.colors.success};
    color: ${props => props.theme.colors.success};
    
    &:hover {
        color: #fff;
        background: ${props => props.theme.colors.success};
    }
`;


const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const AnimationContainer = styled.div`
    animation: ${appearFromLeft} 1s;
`;