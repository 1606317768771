import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: stretch;
`;

export const Background = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.superimposedBackground};
    
    > img {
        width: 80%;
    }

    > div {
        width: 100%;
        max-width: 500px;
        margin-bottom: 32px;
        
        img{
            height: 48px;
            margin-bottom: 16px;
        }
    }
    /* breakpoints */
    @media(max-width: 768px) {
        display: none;
    }
    @media(max-width: 320px) {
        display: none;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 700px;
    background: ${props => props.theme.colors.background};

    form{
        width: 400px;

        h1 {
            margin-bottom: 24px;
        }

        div + div{
            margin-top: 16px;
        }

        button {
            margin-top: 16px;
            width: 100%;
        }

        a{
            text-decoration: none;
            color: ${props => props.theme.colors.text};
            float: right;
            margin: 16px 0;

            &:hover{
                color: ${props => shade(0.2, props.theme.colors.text)};
            }
        }
    }

    /* breakpoints */
    /* @media(min-width: 768px) {
        display: none;
    } */
    @media(max-width: 320px) {
        /* display: none; */
        width: 100%;

        form {
            width: 100%;
            padding: 32px;
            /* margin: 0 auto; */
            h1 {
                color: green;
            }
        }
    }
`;

export const CreateAccount = styled.div`
    width: 400px;

    a{
        margin-top: 16px;
        width: 100%;
        background: transparent;
        border: 2px solid ${props => props.theme.colors.success};
        color: ${props => props.theme.colors.success};
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 8px 16px;
        transition: 0.2s ease;
        text-decoration: none;
        
        &:hover {
            color: #fff;
            background: ${props => props.theme.colors.success};
        }
    }
`;

export const ButtonCreateAccount = styled.a`
    
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const AnimationContainer = styled.div`
    animation: ${appearFromLeft} 1s;
`;