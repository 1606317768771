import { useEffect, useState, useCallback, useMemo, useRef, useContext} from 'react';
import { UseToast } from '../../hooks/ToastContext';
import { useAlert } from '../../hooks/Alert';
import { useAuth } from '../../hooks/Auth';
import { Link } from 'react-router-dom';
import api from '../../utils/api';
import { Form } from '@unform/web';
import { useDisclosure } from '@chakra-ui/react';

import Headline from '../../components/HeadlinePage';
import Button from '../../components/Button';
import CardContainer from '../../components/CardContainer';
import TableUnits from './Table';
import DropDown from '../../components/Dropdown';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';
import exportUnitsPDF from '../../utils/exportUnitsPDF';
import ExportUnitsXLSX from '../../utils/exportUnitsXLSX';
import { SettingsExportDocument } from './SettingsExportDocument';

import Input from '../../components/Input';
import { 
    Heading,
    MenuList,
    Menu,
    MenuButton,
    MenuItem,
    Select,
    Stack,
    Switch,
    Text,
    Modal as ModalSetting,
    ModalOverlay,
    ModalHeader,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
 } from '@chakra-ui/react';

import { FaRegFileExcel, FaRegFilePdf, FaFileExcel, FaFileCsv, FaChevronDown } from 'react-icons/fa';
import { FiPercent, FiSettings } from 'react-icons/fi';
import { 
    ActionsForTable, 
    ContainerFilterTopHeader, 
    ContainerContentModal,
} from './style';
import calculateInstallments from './utils/calculateInstallments';
import { ThemeContext } from 'styled-components';

const statusList = {
    c: { title: "Reservada" },
    d: { title: "Disponível" },
    r: { title: "Reserva técnica" },
    e: { title: "Permuta" },
    m: { title: "Mútuo" },
    p: { title: "Proposta" },
    v: { title: "Vendida" },
    l: { title: "Locado" },
    t: { title: "Transferido" },
}

function InstalmentsFinance()
{
    const formRefInputUnit = useRef(null);
    const { signOut } = useAuth();
    const [ enterprises, setEnterprises ] = useState([]);
    const [ enterprisesSelected, setEnterprisesSelected ] = useState({});
    const [nameEnterprise, setNameEnterprise] = useState("");
    const [ units, setUnits ] = useState([]);
    const [unitsFormat, setUnitsFormat] = useState([]);
    
    const [fees, setFees] = useState(() => {
        const storageValue = localStorage.getItem('@amoraid:configFees');
        if(storageValue) return JSON.parse(storageValue);
        return true;
    });
    
    const [feesValue, setFeesValue] = useState(fees);

    const [selectFees, setSelectFees ] = useState(() => {
        const storageValue = localStorage.getItem('@amoraid:configSelectFees');
        if(storageValue) return JSON.parse(storageValue);
        return null;
    });
    const [selectFeesValue,setSelectFeesValue] = useState(selectFees);

    const [ statusFilterSelected, setStatusFilterSelected ] = useState('all');
    const [ typeFilterSelected, setTypeFilterSelected ] = useState('all');
    const [floor, setFloor] = useState('all');

    const [inputValue, setInputValue] = useState(7);
    
    const [configUser, setConfigUser] = useState({
        table_header_color: '#3B86FF',
        table_price: 0,
    });

    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingUnits, setLoadingUnits] = useState(false);
       
    const [stateVisabledLoading, setStateVisabledLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { addToast } = UseToast();
    const { addAlert } = useAlert();
    const { user } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colors } = useContext(ThemeContext);

    const token = localStorage.getItem('@amoraid:token');

    const config = useMemo(() => ({
        headers: { Authorization: `Bearer ${token}`}
    }), [token]);

    const handleSelectedValue = useCallback((item) => {
        let formatName = item.name.split(' ').join('-');
        setNameEnterprise(formatName);
        if(item) setEnterprisesSelected(item); 
    }, []);

    const handleCheckInputNotExceedTheValue = useCallback((value) => {
        const convertNumber = Number(value);
        if(convertNumber > 100) {
            formRefInputUnit.current.setFieldValue('unitInput', 100);
        }
    }, []);
    
    /** construi os dados para tabela */
    const handleBuildDataForTable = useCallback(async (data, inputValue, configFees = true, configSelectFees = null) => {
        try {
            const { table_price } = configUser;
            
            const dataFormat = await calculateInstallments(
                data, 
                inputValue, 
                table_price, 
                configFees,
                configSelectFees
            );
            
            return dataFormat;
        } catch (error) {
            addToast({
                type: 'error',
                title: error,
            });
        }
    }, [configUser, addToast]);

    const handleSubmitForm = useCallback(async (data) => {
        if(enterprisesSelected){
            try {
                setStateVisabledLoading(true);
                setLoadingTable(true);
                const response = await api.get(`/units`, {
                    ...config,
                    params: {
                        enterpriseId: enterprisesSelected.id,
                        limit: 200,
                    }
                });
               
                if(response.data){
                    console.log(response.data);
                    setUnits(response.data);
                    setFees(true);
                    setFeesValue(true)
                    const data = await handleBuildDataForTable(response.data, 7, true, null);
                    
                    setUnitsFormat(data);
                    /** Confirma que o input esteja apresentando o valor inicial como 7% */
                    formRefInputUnit.current.setFieldValue('unitInput', 7);
                             
                    return;
                }
                setUnits([]);

            } catch (error) {

                if(error.response.status === 401 && error.response.data.message === "User does not have api access credentials."){
                    addAlert({
                        type: 'error',
                        title: 'Você não possui acesso a API',
                        description: <span>Certifique-se de cadastrar as credenciais de acesso ao Api do Sienge, <Link to="/dashboard/settings">clique aqui.</Link></span>
                    });
                    return;
                }
                if(error.response.status === 401 && error.response.data.message === "Invalid JWT token") {
                    addToast({
                        type: 'error',
                        title: 'Sua sessão expirou, efetue o login.'
                    });
                    signOut();
                    return;
                }
                addToast({
                    type: 'error',
                    title: 'houve um erro na requisição'
                });
            }finally{
                setStateVisabledLoading(false);
                setLoadingTable(false);
            }
        }
    }, [addAlert, addToast, config, enterprisesSelected, handleBuildDataForTable, signOut]);
    /**
     * Exporta os dados em pdf
     */
    const handleExportPDF = useCallback(async () => {
        addToast({
            type: 'info',
            title: 'Aguarde, estamos processando sua exportação.',
            description: 'isso pode demorar alguns segundos 😉'
        });

        setLoadingUnits(true);
        
        await exportUnitsPDF(
            unitsFormat, 
            enterprisesSelected, 
            nameEnterprise,
            configUser.table_header_color
        );
        
        setLoadingUnits(false);
        
    },[unitsFormat, enterprisesSelected, nameEnterprise, addToast, configUser]);
    
    /** Lida com o 'submit' do formulario de entrada */
    const handleChangeUniInput = useCallback(async (value) => {
        const parseInputValue = Number(value.unitInput);
        setInputValue(parseInputValue);
    }, []);
    
    const handleExportXLSX = useCallback(() => {
        ExportUnitsXLSX({
            data: unitsFormat,
            filename: nameEnterprise,
        });
    }, [unitsFormat, nameEnterprise]);

    const handleExportXLML = useCallback(() => {
        ExportUnitsXLSX({
            data: unitsFormat,
            filename: nameEnterprise,
            bookType: 'xlml',
        });
    }, [unitsFormat, nameEnterprise]);

    const handleExportCSV = useCallback(() => {
        ExportUnitsXLSX({
            data: unitsFormat,
            filename: nameEnterprise,
            bookType: 'csv',
        });
    }, [unitsFormat, nameEnterprise]);
    /** Create status filter */
    const handleStatusUnits = useCallback(() => {
        var status = {};
        status.all = {
            title: 'Todos',
            commercialStock: 'all',
        };
        if(units.length < 1) return status;
        units.forEach(unit => {
            if(!status[unit.commercialStock]){
                status[unit.commercialStock] = statusList[unit.commercialStock.toLowerCase()] 
                ? {...statusList[unit.commercialStock.toLowerCase()], commercialStock: unit.commercialStock} 
                : { title: unit.commercialStock, commercialStock: unit.commercialStock };
            }
        });
        return status;
    }, [units]);

    const handleTypeProperty = useCallback(() => {
        var status = {};
        status.all = {
            title: 'Todos',
            propertyType: 'all',       
        };
        if(units.length < 1) return status;
        units.forEach(unit => {
            if(!status[unit.propertyType]) {
                status[unit.propertyType] = statusList[unit.propertyType.toLowerCase()] 
                ? {...statusList[unit.propertyType.toLowerCase()], propertyType: unit.propertyType} 
                : { title: unit.propertyType, propertyType: unit.propertyType };
            }
        });
        return status;
    }, [units]);

    const buildFloorForFilter = (units) => {
        var status = {};
        status.all = {
            title: 'Todos',
            floor: 'all',       
        };
        if(units.length < 1) return status;
        units.forEach(unit => {
            if(unit.floor) {
                if(!status[unit.floor]) {
                    status[unit.floor] = statusList[unit.floor.toLowerCase()] 
                    ? {...statusList[unit.floor.toLowerCase()], floor: unit.floor} 
                    : { title: unit.floor, floor: unit.floor };
                }
            }
        });
        return status;
    }

    const handleOpenModal = useCallback(() => {
        setShowModal(true);
    }, []);

    function handleSaveSettings() {
        const parseSelectFees = JSON.stringify(selectFeesValue);
       
        if(!fees) {
            localStorage.setItem('@amoraid:configSelectFees', parseSelectFees);
            setSelectFees(selectFeesValue);
        }

        localStorage.setItem('@amoraid:configFees', fees);
        
        setFees(feesValue);
        onClose();
    }

    /** filter for status or property type */
    useEffect(() => {
        (async function(){
            if(!units.length) return;
            if(!typeFilterSelected || !statusFilterSelected) return;
            
            setLoadingTable(true);
            let unitsFormat = await handleBuildDataForTable(
                units, 
                inputValue, 
                fees,
                selectFees,
            );
            
            let filterData = unitsFormat.filter(item => {
                if(statusFilterSelected === 'all' && typeFilterSelected === 'all' && floor === 'all') return item;
                if(typeFilterSelected === 'all' && floor === 'all' && statusFilterSelected !== 'all') 
                return item.commercialStock === statusFilterSelected;
                
                if(statusFilterSelected === 'all' && floor === 'all' && typeFilterSelected !== 'all') 
                return item.propertyType === typeFilterSelected;

                if(statusFilterSelected === 'all' && typeFilterSelected === 'all' && floor !== 'all') 
                return item.floor === floor;
                /** variant */
                if(typeFilterSelected === 'all' && floor !== 'all' && statusFilterSelected !== 'all') 
                return item.commercialStock === statusFilterSelected && item.floor === floor;
                
                if(floor === 'all' && statusFilterSelected !== 'all'  && typeFilterSelected !== 'all') 
                return item.propertyType === typeFilterSelected && item.commercialStock === statusFilterSelected;

                if(statusFilterSelected === 'all' && typeFilterSelected !== 'all' && floor !== 'all') 
                return item.floor === floor && item.propertyType === typeFilterSelected;

                return item.propertyType === typeFilterSelected 
                && item.commercialStock === statusFilterSelected
                && item.floor === floor;
            });

            setUnitsFormat(filterData);
            setLoadingTable(false);
        })();
    }, [typeFilterSelected, statusFilterSelected, units,handleBuildDataForTable, inputValue, fees, selectFees, floor]);

    /** get enterprises */
    useEffect(() => {
        async function getEnterprises()
        {
            try {
                setLoadingUnits(true);
                const response = await api.get('/enterprises?limit=200', config);
               
                if(response.data) setEnterprises(response.data.enterprises);

            } catch (reason) {
                if(reason.response.status === 401 && reason.response.data.message === "User does not have api access credentials."){
                    addAlert({
                        type: 'error',
                        title: 'Você não possui acesso a API',
                        description: <span>Certifique-se de cadastrar as credenciais de acesso ao Api do Sienge, <Link to="/dashboard/settings">clique aqui.</Link></span>
                    });
                }
                if(reason.response.status === 401 && reason.response.data.message === "Invalid authentication credentials"){
                    addAlert({
                        type: 'error',
                        title: 'Erro na autenticação de API.',
                        description: <span>Certifique-se que as credenciais cadastrada estão corretas, para atualizar <Link to="/dashboard/settings">clique aqui.</Link></span>
                    });
                }
            }finally{
                setLoadingUnits(false);
            }

        }

        getEnterprises();

        async function getConfigUser(){
            try {
                const response = await api.get(`/settings/${user.id}`, config);
                if(response.data) setConfigUser(response.data);
            } catch (error) { }
        }
        getConfigUser();
    }, [addAlert, config, user.id]);
    return (
        <>
        <Loading isVisabled={stateVisabledLoading} />
        {/* Modal Setting export document */}
        <Modal title="Configuração do documento" 
        showModal={showModal} setShowModal={setShowModal}>
           <SettingsExportDocument />
        </Modal>
        {/* Modal Settings Table Price */}
        <ModalSetting isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            <ModalOverlay />
                <ModalContent background={colors.superimposedBackground}>
                    <ModalHeader>Configuração de consulta</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <ContainerContentModal>
                            <Stack align="center" direction="row" width={200} marginBottom={4}>
                                <strong>Table price</strong>
                                <Switch isChecked={feesValue} onChange={() => setFeesValue(!feesValue)}/>
                            </Stack>
                            {!feesValue && (
                                <DropDown 
                                    prompt="A partir de"
                                    id="table-price"
                                    label="label"
                                    onChange={value => setSelectFeesValue(value)}
                                    options={optionsNumberOfInstallments}
                                    value={selectFeesValue}
                                    overlay
                                />
                            )}
                            <Text fontSize="xs" color="gray.500" marginTop={!fees ? 4 : 0}>Table price ativada irá aplicar em todos as parcelas, 
                            desativada você tem opção de selecionar a partir de qual parcela que pode ser aplicada ou nenhuma.</Text>
                        </ContainerContentModal>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorStyle="secondary" onClick={onClose}>Fechar</Button>
                        <Button colorStyle="primary" onClick={handleSaveSettings} style={{marginLeft: 16}}>Aplicar</Button>
                    </ModalFooter>

            </ModalContent>
        </ModalSetting>
        {/* Content Main */}
        <Headline>
            <div className="description">
                <Heading as="h1" size="lg">Financiamento de unidades</Heading>
                <p>Para consultar a sua obra selecione o filtro ao lado.</p>
            </div>
            <Form onSubmit={handleSubmitForm} style={{width: 400}}>
                <DropDown 
                    prompt="Procurar empreendimento"
                    id="enterprises"
                    label="name"
                    onChange={handleSelectedValue}
                    options={enterprises}
                    loading={loadingUnits}
                />
                <Button type="submit" colorStyle="primary">Consultar</Button>
            </Form>
        </Headline>
        <CardContainer>
        <ActionsForTable>
            <strong>Tabela de Vendas - <span>{!!enterprisesSelected && enterprisesSelected.name}</span></strong>
            <div>
                <Form onSubmit={handleChangeUniInput} ref={formRefInputUnit} initialData={{ unitInput: 7 }}>
                    <div>
                        <Input 
                            name="unitInput" 
                            id="unitInput" 
                            label="Entrada" 
                            Icon={FiPercent} 
                            overlay 
                            type="number"
                            onChange={handleCheckInputNotExceedTheValue}
                        />
                        <small>Pressione 'Enter' ou 'Calcular'.</small>
                    </div>
                    <Button colorStyle="success" type="submit">Calcular</Button>
                </Form>
                {!!units.length && (
                    <div style={{marginLeft: 16}}>
                        <Menu>
                            <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                                Exportar
                            </MenuButton>
                            <MenuList background={colors.superimposedBackground}>
                                <MenuItem onClick={handleExportXLSX}>
                                    <FaRegFileExcel size={16} style={{ marginRight: 8 }}/> XLSX
                                </MenuItem>
                                <MenuItem onClick={handleExportXLML}>
                                    <FaFileExcel size={16} style={{ marginRight: 8 }}/> XLML
                                </MenuItem>
                                <MenuItem onClick={handleExportCSV}>
                                    <FaFileCsv size={16} style={{ marginRight: 8}}/> CSV
                                </MenuItem>
                                <MenuItem onClick={handleExportPDF}>
                                    <FaRegFilePdf size={16} style={{ marginRight: 8}}/> PDF
                                </MenuItem>
                                <MenuItem onClick={handleOpenModal}>
                                    <FiSettings size={16} style={{ marginRight: 8}}/> Configurar exportação
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </div>
                )}
                <Button type="button" onClick={onOpen}  colorStyle="secondary" style={{ marginLeft: 16 }}>
                    <FiSettings />
                </Button>
            </div>
        </ActionsForTable>
            <ContainerFilterTopHeader>
                <div className="filter">
                    <Select 
                        variant="filled" 
                        placeholder="Filtrar por status" 
                        onChange={({ target }) => setStatusFilterSelected(target.value)}
                        >
                        {units.length && Object.values(handleStatusUnits()).map(status => (
                            <option value={status.commercialStock}>{status.title}</option>
                        ))}
                    </Select>
                    <Select 
                        variant="filled" 
                        placeholder="Filtrar por tipo de proriedade"
                        onChange={({ target }) => setTypeFilterSelected(target.value)}
                        >
                        {units.length && Object.values(handleTypeProperty()).map(type => (
                            <option value={type.propertyType}>{type.title}</option>
                        ))}
                    </Select>
                    <Select 
                        variant="filled" 
                        placeholder="Filtrar por pavimento"
                        onChange={({ target }) => setFloor(target.value)}
                        >
                        {units.length && Object.values(buildFloorForFilter(units)).map(type => (
                            <option value={type.floor}>{type.title}</option>
                        ))}
                    </Select>
                </div>
                <div className="description">
                    <small>Tabela price: <strong>{configUser.table_price} %</strong></small>
                    <small style={{marginLeft: 16}}>Resultado: <strong>{unitsFormat.length}</strong></small>
                </div>
            </ContainerFilterTopHeader>
            <TableUnits 
                enterprise={enterprisesSelected}
                exportPDF={handleExportPDF}
                units={unitsFormat}
                loadingTable={loadingTable}
                headers={headerTable}
            />                
        </CardContainer>
        </>
    );
}

const headerTable = [
    "Status",
    "Tipo da propriedade",
    "Quadra",
    "Lote",
    "Área",
    "Á vista",
    "Financiamento",
    "m12",
    "m24",
    "m36",
    "m48",
    "m60",
    "m72",
    "m84",
    "m96",
    "m108",
    "m120",
    "m132",
    "m144",
    "m156",
    "m168",
    "m180"
];


const optionsNumberOfInstallments = [
    {value: null, label: '#NULO'},
    {value: 12, label: '12x'},
    {value: 24, label: '24x'},
    {value: 36, label: '36x'},
    {value: 48, label: '48x'},
    {value: 60, label: '60x'},
    {value: 72, label: '72x'},
    {value: 84, label: '84x'},
    {value: 96, label: '96x'},
    {value: 108, label: '108x'},
    {value: 108, label: '108x'},
    {value: 120, label: '120x'},
    {value: 132, label: '132x'},
    {value: 144, label: '144x'},
    {value: 156, label: '156x'},
    {value: 168, label: '168x'},
    {value: 180, label: '180x'},
];

export default InstalmentsFinance;