import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { darken } from 'polished';

export const Container = styled(animated.div)`
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    z-index: 99;
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    ${props => props.visibled && css`
        display: block;
    `}
    
    .content {
        width: 70%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        max-height: 85vh;
        min-height: 50%;
        background: ${props => props.theme.colors.superimposedBackground};
        border-radius: 10px;

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                padding: 8px;
                font-size: 16px;
                border-radius: 4px;
                background: transparent;
                border: 0;
                transition: .2s;
                color: #fff;
                &:hover {
                    background: ${props => darken(0.02, props.theme.colors.superimposedBackground)};
                }
            }
        }

        main {
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            height: 100%;
            position: relative;
            overflow-y: auto;
        }
    }  
`;
