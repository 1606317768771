import React, { useCallback } from 'react';

import XLSX from 'xlsx';

var data = [
  {"name":"John", "city": "Seattle"},
  {"name":"Mike", "city": "Los Angeles"},
  {"name":"Zach", "city": "New York"}
];

function Layout()
{
  const handleExportSheet = useCallback(() => {
    var ws = XLSX.utils.json_to_sheet(data);
    
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "WorksheetName");

    XLSX.writeFile(wb, "sheetjs.xlsx");

    
  }, []);
  return (
      <div>
          <button onClick={handleExportSheet}>Download</button>
      </div>
  );
}


export default Layout;