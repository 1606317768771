import styled, { css } from 'styled-components';


export const Container = styled.div`
    padding: 32px;
    border-radius: 10px;
    background: ${props => props.theme.colors.superimposedBackground};
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid transparent;

    ${props => (props.theme.title === 'light') && css`
        /* border-color: #284053; */
        box-shadow: 0 0 0 1px rgb(0 0 0 / 12%), 0 20px 25px -10px rgb(0 0 0 / 5%);
    `};

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    > strong {
        display: block;
        margin-bottom: 16px;

        small {
            color: ${props => props.theme.colors.primary};
        }
    }

    > small strong {
        color: ${props => props.theme.colors.primary};
    }

    /* & + div {
        margin-top: 32px;
    } */
`;