import Alert from './Alert';
import { useTransition } from 'react-spring';

function AlertContainer({ messages })
{
    const messagesWithTransitions = useTransition(
        messages, 
        message => message.id,
        {
            from: { top: '-120%', opacity: 0 },
            enter: { top: '0%', opacity: 1 },
            leave: { top: '-120%', opacity: 0 },
            transitionDuration: 250
        }
        );
    return (
        <div>
            {messagesWithTransitions.map(({item, key, props}) => (
                <Alert key={key} type="error" message={item} style={props}/>
            ))}
        </div>
    );
}

export default AlertContainer;