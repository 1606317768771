import styled from 'styled-components';

export const Container = styled.div`
    header{
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    .header {
        margin-bottom: 32px;
    }
`;

export const Headline = styled.h1`
    margin-bottom: 16px;
    span{
        color: ${props => props.theme.colors.primary};
    }
`;
