import React from 'react';

import ThemeContext from './ToggleThemeContext';
import { AuthProvider } from './Auth';
import { ToastProvider } from './ToastContext';
import { AlertProvider } from './Alert';

const AppProvider = ({children}) => {
    return (
        <ThemeContext>
                <AuthProvider>
                    <ToastProvider>
                        <AlertProvider>
                            {children}
                        </AlertProvider>
                    </ToastProvider>
                </AuthProvider> 
        </ThemeContext>
    );
};

export default AppProvider;