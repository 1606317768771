/** 
 * Formula 
 * 
 * pmt = pv / ((Math.pow((1 + i), n) - 1) / (Math.pow((1 + i), n) * i))
 */

/**
 * 
 * @param Number (Present Value) pv 
 * @param Number (Rate) i 
 * @param Number (plots) n 
 */
const calculateFinancing = (pv, rate, n ) => {

    if(rate === 0 ){
        return pv / n;
    }
    
    var pmt = 0;
    var i = rate / 100;
    
    pmt = pv / ((Math.pow((1 + i), n) - 1) / (Math.pow((1 + i), n) * i));

    return pmt;
}

export default calculateFinancing;