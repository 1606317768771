import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    },
    styles: {
        global: {
            body: {
                bg: '#212529',
            }
        }
    },
    colors: {
        blue: {
            100: '#3B86FF',
        }
    },
    layerStyles: {
        base: {
          bg: "gray.50",
          border: "2px solid",
          borderColor: "gray.500",
        },
    }
});