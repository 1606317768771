import { darken, shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    ul {
        list-style: none;

        li {
            display: flex;
            padding: 16px 8px;
            align-items: flex-start;

            & + li {
                border-top: 1px solid ${props => darken(0.05, props.theme.colors.superimposedBackground)};
            }
            .date {
                padding-top: 32px;
            }
            .content {
                flex: 1;
                
                header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    
                    strong {
                        display: block;
                        font-size: 24px;
                        font-weight: 900;
                        margin-bottom: 16px;
                    }

                    .badge-new {
                        padding: 8px 16px;
                        border-radius: 10px;
                        font-size: 12px;
                        background: ${props => props.theme.colors.primary};
                        color: ${props => props.theme.colors.text};
                    }
                }

                .body-content {
                    flex: 1;
                    padding-right: 64px;

                    button {
                        margin-top: 8px;
                        background: transparent;
                        border: 0;
                        font-size: 16px;
                        color: ${props => props.theme.colors.primary};
                        transition: 0.2s;
                        &:hover {
                            color: ${props => shade(0.2, props.theme.colors.primary)};
                        }
                    }
                }
            }
        }
    }
`;


export const NotChangelogs = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    border-radius: 10px;
    background: ${props => darken(0.02, props.theme.colors.superimposedBackground)};
`;