import { useAlert } from '../../../hooks/Alert';
import { Container } from './style';
import { 
    FiInfo, 
    FiAlertCircle, 
    FiCheckCircle, 
    FiXCircle, 
} from 'react-icons/fi';

const icons = {
    info: <FiInfo size={24} />,
    error: <FiAlertCircle size={24}/>,
    success: <FiCheckCircle size={24} />
}
const Alert = ({message, style}) => {
    const { removeAlert } = useAlert();
    
    return (
        <Container key={message.id} type={message?.type} style={style}>
            <div className="info">
                {icons[message?.type || 'info']}
                <div className="description">
                    <strong>{message.title}</strong>
                    <p>{message.description}</p>
                </div>
            </div>
            <button>
                <FiXCircle size={24} onClick={() => removeAlert(message.id)}/>
            </button>
        </Container>
    );
}

export default Alert;