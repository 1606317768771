import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export default function MyModal({ isOpen = false, onOpen, onClose, title = '', children, ...rest }) {

    const { colors } = useContext(ThemeContext);

    return (
        <Modal isOpen={isOpen} onClose={onClose} {...rest}>
            <ModalOverlay />
            <ModalContent background={colors.superimposedBackground}>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                {children}
            </ModalContent>
        </Modal>
    );
}