import styled from 'styled-components';
import { darken } from 'polished';

export const HelpDomain = styled.span`
    font-size: 12px;
    color: ${props => props.theme.colors.label};

    strong {
        display: inline;
        font-weight: 800;
    }
`;
export const ColumnTwo = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;

    & + div {
        margin-top: 16px;
    }
`;

export const JustifyEnd = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const ContainerRegisterLogotipo = styled.div`
    display: flex;

    /* justify-content: center; */
    align-items: center;
    padding: 32px 16px 16px;

    > div + div {
        margin-left: 32px;
    }
`;

export const ContainerDropdown = styled.div`
    margin-bottom: 16px;
`;

export const ContainerNotImage = styled.div`
    height: 250px;
    width: 250px;
    padding: 16px;
    
    background: ${props => darken(0.02, props.theme.colors.superimposedBackground)};
    
    border: 2px dashed ${props => darken(0.05, props.theme.colors.superimposedBackground)};
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    img{
        width: 100%;
    }
`;