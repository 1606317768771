import React, { createContext, useContext, useCallback, useState } from 'react';
import api from '../utils/api';

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [data, setData] = useState(() => {
        const token = localStorage.getItem('@amoraid:token');
        const user = localStorage.getItem('@amoraid:user');

        if(token && user){
            return {token, user: JSON.parse(user)};
        }

        return {};
    });

    const signIn = useCallback(async ({ email, password }) => {
        try {
            const response = await api.post("/sessions", {email, password});
            const { token, user } = response.data;
            
            localStorage.setItem('@amoraid:token', token);
            localStorage.setItem('@amoraid:user', JSON.stringify(user));

            setData({ token, user });
        } catch {
            throw new Error("Email/Senha não parece válidos.");
        }
    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem('@amoraid:token');
        localStorage.removeItem('@amoraid:user');
        setData({});
    }, []);
    
    return (
        <AuthContext.Provider value={{user: data.user, signIn, signOut}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    // const { signIn, signOut } = context;
    if(!context){
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
}
