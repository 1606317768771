import React, { useEffect } from 'react';
import { Route as ReactDOMRoute, Redirect, useHistory } from 'react-router-dom';
import api from '../utils/api';

import { useAuth } from '../hooks/Auth';
import Dashboard from '../components/Dashboard';

const Route = ({isPrivate = false, component: Component, ...rest}) => {
    const { user, signOut } = useAuth();
    const history = useHistory();
    useEffect(() => {

        async function verifyToken()
        {
            if(isPrivate && !!user)
            {
                const token = localStorage.getItem('@amoraid:token');
                const config = {
                    headers: { Authorization: `Bearer ${token}`}
                };
                try {
                    await api.get('/sessions', config)
                } catch (reason) {
                    const { status } = reason.response
                    if(status && status === 401){
                        signOut();
                        history.push('/');
                    }
                }
    
            }
        }
        verifyToken();
    }, [isPrivate, history, signOut, user]);
    
    return (
        <ReactDOMRoute 
        {...rest} 
        render={({ location }) => {
            return isPrivate === !!user ? (
                isPrivate ? (
                    <Dashboard>
                        <Component />
                    </Dashboard>
                ) : (<Component />)
            ) : (
                <Redirect 
                to={{ 
                    pathname: isPrivate ? '/' : '/dashboard',
                    state: { from: location },
                }} />
            )
        }}/>
    )
};

export default Route;