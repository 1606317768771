import React, { useCallback, useEffect } from 'react';
import { Container } from './style';
import { FiX } from 'react-icons/fi';
import { useTransition } from 'react-spring';

const Modal = ({title, children, showModal, setShowModal}) => {
    
   
    const transitions = useTransition(showModal, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    });


    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    const handleHideModalKeyPress = useCallback(e => {
        if(e.key === 'Escape' && showModal){
            setShowModal(false);
        } 
    }, [setShowModal, showModal]);


    useEffect(() => {
        document.addEventListener('keydown', handleHideModalKeyPress);
        return () => document.removeEventListener('keydown', handleHideModalKeyPress);
    }, [handleHideModalKeyPress]);
    
    return (
        <>
        {transitions.map(({ item, key, props: style }) => item && (
            <Container toggleModal={showModal} style={style} key={key}>
                <div className="content">
                    <header>
                        <h2>{title}</h2>
                        <button onClick={handleCloseModal}><FiX /></button>
                    </header>
                    <main>
                        <div>
                            {children}
                        </div>
                    </main>
                </div>
            </Container>
        ))}
        </>
    );
}

export default Modal;